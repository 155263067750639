import React from "react";
import DayList from "../../../components/common/day-list/DayList";
import FeedbackSection from "../../../components/common/FeedbackSection";
import MapView from "../../../components/pages/itinerary/MapView";
import { useItineraryPageContext } from "../../../contexts/ItineraryPageContext";
import { Membership } from "../../../models/classes/Membership.class";
import Cover from "./Cover";
import { Trip } from "../../../models/classes/Trip.class";

type Props = {
  city: string;
};

function MobileLayout({ city }: Props) {
  const {
    currentSelectedDay,
    itinerary,
    trip,
    tripId,
    handleUpdateItinerary,
    setCurrentSelectedDay,
    stripeCustomer: { user, stripeCustomerInfo },
  } = useItineraryPageContext();

  const tripObj = trip ? new Trip(trip) : null;

  return (
    <div className="w-full lg:hidden h-full gap-y-5 flex flex-col overflow-hidden">
      <FeedbackSection user={user} />
      {currentSelectedDay !== null ? (
        trip ? (
          <MapView
            trip={trip}
            currentSelectedDay={currentSelectedDay}
            city={city}
          />
        ) : null
      ) : itinerary && tripId ? (
        <Cover itinerary={itinerary} tripId={tripId} isPreset={false} />
      ) : null}

      {tripId ? (
        <DayList
          tripId={tripId}
          tripDetail={itinerary}
          handleUpdateItinerary={handleUpdateItinerary}
          onSetCurrentSelectedDay={setCurrentSelectedDay}
          currentSelectedDay={currentSelectedDay}
          isPremiumUser={
            // Membership.isValid(stripeCustomerInfo)
            true
          }
          isTripInThePast={tripObj ? tripObj.hasEnded() : false}
          city={city}
        />
      ) : null}
    </div>
  );
}

export default MobileLayout;
