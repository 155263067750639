import React, { FC } from "react";

type Props = {
  showLoadingAnimation?: boolean;
};

const LoadingDestinations: FC<Props> = ({ showLoadingAnimation = true }) => {
  return (
    <>
      <div
        className={`h-[150px] min-w-[200px] lg:max-w-full lg:min-w-[300px] bg-white shadow-lg rounded-2xl ${
          showLoadingAnimation ? "animate-pulse" : ""
        } my-4 p-4 flex flex-col justify-between overflow-hidden`}
      >
        <div
          className={`w-full h-1/2 bg-gray-200 rounded-2xl ${
            showLoadingAnimation ? "animate-pulse" : ""
          }`}
        ></div>
        <span className={`w-full h-[20px] bg-gray-200 rounded-full`}></span>
        <span className={`w-2/3 h-[20px] bg-gray-200 rounded-full`}></span>
      </div>
    </>
  );
};

export default LoadingDestinations;
