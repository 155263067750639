import { Stack } from "@mui/material";
import React, { FC, ReactNode } from "react";

type Props = {
  children: ReactNode;
  axis?: "x" | "y";
  gap?: number;
};

const Container: FC<Props> = ({ children, axis = "y", gap = 0 }) => {
  return (
    <Stack
      className={"max-h-svh w-full px-5 py-2 overflow-scroll text-black"}
      direction={axis === "y" ? "column" : "row"}
      gap={gap}
    >
      {children}
    </Stack>
  );
};

export default Container;
