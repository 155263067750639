import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { FC } from "react";
import {
  addDaysForTripCardDate,
  formatTripCardDate,
} from "../../../libs/utils/date.util";
import { TripDetail } from "../../../models/trip.model";

type Props = {
  itinerary: TripDetail | null;
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    color: "black",
    width: "100%",
    height: "100%",
    padding: 10,
    gap: 10,
    alignItems: "center",
  },

  nav: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },

  navTitle: {
    fontSize: 20,
    fontWeight: "bold",
  },
  logo: {
    fontSize: 20,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 12,
    width: "70%",
  },
  destinationTitle: {
    fontSize: 12,
    fontWeight: "bold",
  },
  destinationName: {
    fontSize: 12,
    textAlign: "center",
  },

  destinations: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    rowGap: 10,
  },

  destination: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
  },

  tableHeaderContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    border: "1px solid black",
  },

  destinationListContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
});

const ItineraryPDF: FC<Props> = ({ itinerary }) => {
  if (!itinerary) return null;

  const { date, duration, name } = itinerary;

  console.log(itinerary)

  const tripEndDate = date
    ? addDaysForTripCardDate(date, Number(duration), "string")
    : "-";

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.nav}>
          <Text style={styles.navTitle}>Travel Itinerary</Text>
          <Text style={styles.logo}>Eleplan.ai</Text>
        </View>
        <View style={styles.header}>
          <Text>
            <Text style={{ fontWeight: "bold" }}>Trip Start :</Text>{" "}
            {date ? formatTripCardDate(date) : "-"}
          </Text>
          <Text style={{ fontWeight: "bold" }}>
            Trip End : {tripEndDate.toLocaleString()}
          </Text>
        </View>
        <Text style={styles.destinationTitle}>
          Destination : <Text style={styles.destinationName}>{name}</Text>
        </Text>
        <View style={styles.destinations}>
          {Object.keys(itinerary.itinerary).map((day, _) => (
            <View key={day} style={styles.destination}>
              <Text
                style={{ fontSize: 16, fontWeight: "bold", marginBottom: 10 }}
              >
                {day}
              </Text>
              <View style={styles.tableHeaderContainer}>
                <View
                  style={{
                    width: "15%",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 5,
                    borderRight: "1px solid black",
                  }}
                >
                  <Text style={{ fontSize: 12, fontWeight: "bold" }}>Time</Text>
                </View>
                <View
                  style={{
                    width: "85%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    padding: 5,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                  >
                    Destination
                  </Text>
                </View>
              </View>
              <View style={styles.destinationListContainer}>
                {Object.values(itinerary.itinerary[day]).map(
                  (destination, index) => {
                    return (
                      <View
                        key={destination.name_place}
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid black",
                        }}
                      >
                        <View
                          style={{
                            width: "15%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 5,
                            borderRight: "1px solid black",
                          }}
                        >
                          <Text style={{ fontSize: 12 }}>
                            {Object.keys(itinerary.itinerary[day])[index]}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "85%",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: 5,
                          }}
                        >
                          <Text style={{ fontSize: 12 }}>
                            {destination.name_place}
                          </Text>
                        </View>
                      </View>
                    );
                  }
                )}
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default ItineraryPDF;
