import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
} from "@nextui-org/react";
import React, { FC } from "react";
import { CustomModal, Parent } from "../../../models/component.model";

const FormModal: FC<CustomModal<Parent & { confirmButtonLabel: string }>> = ({
  children,
  isOpen,
  confirmButtonLabel,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal
      className="mx-5"
      placement="center"
      classNames={{
        closeButton: "bg-white",
      }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button onClick={onClose} fullWidth>
            <p className="text-buttonTextColor font-semibold">Cancel</p>
          </Button>
          <Button color="primary" onClick={onConfirm} fullWidth>
            <p className="text-black font-semibold">{confirmButtonLabel}</p>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FormModal;
