import React from "react";
import toast from "react-hot-toast";
import { Tag } from "../../../components/itinerary/Tag";
import { useItineraryPageContext } from "../../../contexts/ItineraryPageContext";
import { addDestination } from "../../../helper/itinerary.helper";
import { updateTripById } from "../../../libs/services/trip.service";

const DestinationItem = ({ destination, currentDay }) => {
  const { category, name_place, DCT } = destination;
  
  const { tripId, handleUpdateItinerary, itinerary } =
    useItineraryPageContext();

  async function handleAddItinerary() {
    try {
      if (!itinerary) throw new Error("Itinerary is not found");

      if (!destination) throw new Error("Please select a destination");

      if (!currentDay) throw new Error("Please select a day");

      const updatedItinerary = addDestination(
        itinerary.itinerary,
        destination,
        currentDay
      );

      toast.loading("Adding the destination...");

      await updateTripById(tripId as string, updatedItinerary);

      handleUpdateItinerary(updatedItinerary);

      toast.dismiss();

      toast.success("Added the destination");
    } catch (error) {
      toast.error(error.message);
    }
  }
  return (
    <div
      onClick={handleAddItinerary}
      className="flex flex-col bg-[#f5f5f5] lg:h-full w-full min-h-[150px] min-w-[400px] rounded-[15px] p-[10px] cursor-pointer justify-between"
    >
      <h1 className="text-sm font-semibold">{name_place}</h1>
      <p className="text-sm text-gray-400 line-clamp-3">
        {DCT ? DCT : "No description"}
      </p>
      {category.includes(",") ? (
        <div className="flex gap-2 flex-wrap">
          {category.split(",").map((tag) => {
            return <Tag key={tag} content={tag} />;
          })}
        </div>
      ) : (
        <Tag content={category} />
      )}
    </div>
  );
};

export default DestinationItem;
