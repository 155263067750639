import { Stack } from "@mui/material";
import { Chip } from "@nextui-org/react";
import { motion } from "framer-motion";
import React, { FC, useState } from "react";
import { FaEllipsisH } from "react-icons/fa";
import {
  formatDay,
  getTimeDifferenceForTripCard,
} from "../../../libs/utils/date.util";
import { Trip } from "../../../models/classes/Trip.class";
import { DATE_FORMAT } from "../../../models/enums/date.enum";
import { Trip as TripType } from "../../../models/trip.model";
import TripDateDetail from "./TripDateDetail";
import TripPopUp from "./TripPopUp";

type Props = {
  trip: TripType;
  onDeleteItinerary: () => void;
  onClick: () => void;
  index: number;
};

const TripCard: FC<Props> = ({ trip, onDeleteItinerary, onClick, index }) => {
  const tripObj = new Trip(trip);

  const { date, imgCover, name, duration } = trip.itinerary;

  const [showPopUp, setShowPopUp] = useState(false);

  const remainingDays = getTimeDifferenceForTripCard(date, new Date());

  function handleShowPopup(value: boolean) {
    setShowPopUp((_) => value);
  }

  return (
    <motion.div
      initial={{
        opacity: 0,
        scale: 0.5,
      }}
      animate={{
        opacity: 1,
        scale: 1,
      }}
      transition={{
        type: "spring",
        duration: 1,
        delay: 0.2 * index,
      }}
    >
      <div
        onClick={onClick}
        className="flex flex-col gap-y-[15px] h-full w-full lg:min-w-0 overflow-hidden relative cursor-pointer"
      >
        <div className="h-5/6 rounded-xl overflow-hidden relative">
          <div className="w-full h-full">
            <div className="w-full h-full absolute bg-black bg-opacity-40"></div>
            <img className="w-full h-[260px] object-cover" src={imgCover} />
          </div>
          {showPopUp ? (
            <TripPopUp
              handleShowPopup={handleShowPopup}
              onDeleteItinerary={onDeleteItinerary}
            />
          ) : null}
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowPopUp((prev) => !prev);
            }}
            className="absolute top-2 right-2 bg-black bg-opacity-50 rounded-full p-2"
          >
            <FaEllipsisH className="text-white text-sm" />
          </div>
          <Chip className="absolute top-4 left-4 bg-white p-4">
            <p className="font-semibold">
              {remainingDays < 0
                ? tripObj.hasEnded()
                  ? "Trip Ended"
                  : "Trip Started"
                : `${remainingDays} days left`}
            </p>
          </Chip>
        </div>
        <Stack className="ml-3 h-1/6" gap={1}>
          <h3 className="font-semibold text-black">{name}</h3>
          <TripDateDetail
            startDate={date ?? ""}
            duration={Number(duration)}
            endDate={
              formatDay(
                DATE_FORMAT.mm_dd_yyyy,
                tripObj.getTripEndDate()?.toLocaleDateString(),
                "/"
              ) ?? ""
            }
          />
        </Stack>
      </div>
    </motion.div>
  );
};

export default TripCard;
