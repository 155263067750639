import moment from "moment";
import { TripSchema, Trip as TripType } from "../trip.model";

// include the trip start date so subtract 1 when calculating the trip end date
// Let's say start trip date is 20/12/2024 and duration is 2 days, the trip end date should be 21/12/2024
const DAYS_OFFSET = 1;

export class Trip {
  private trip: TripType;

  constructor(trip: TripType) {
    this.trip = trip;
  }

  getTripDate(): Date | null {
    const { date } = this.trip.itinerary;

    if (!date) return null;

    return new Date(date);
  }

  getTripEndDate(): Date | null {
    const tripEndDate = moment(this.getTripDate())
      .add(this.getDuration() - DAYS_OFFSET, "days")
      .toDate();

    return tripEndDate;
  }

  getDuration(): number {
    return parseInt(this.trip.itinerary.duration);
  }

  hasEnded(): boolean {
    return new Date() >= (this.getTripEndDate() ?? new Date());
  }

  return(): TripType {
    return this.trip;
  }
}
