import {
  CalendarDate,
  getLocalTimeZone,
  parseDate,
  today,
} from "@internationalized/date";
import { Button, DatePicker, Modal, ModalContent } from "@nextui-org/react";
import { User } from "@supabase/supabase-js";
import React, { FC, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { clonePresetTrip } from "../../../libs/services/itinerary-preset.service";
import { TModal } from "../../../models/component.model";
import { Empty } from "../../../models/misc.model";

type Props = {
  user: User | Empty;
  presetId: string | Empty;
};

function formatTripDate(tripDate: CalendarDate): string {
  const { year, month, day } = tripDate;

  return `${year}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  }`;
}

const DateFormModal: FC<TModal & Props> = ({
  user,
  presetId,
  ...modalProps
}) => {
  const [tripDate, setTripDate] = useState<CalendarDate>(
    today(getLocalTimeZone())
  );

  const navigate = useNavigate();

  async function handleClonePreset() {
    try {
      if (!user) {
        toast.error("Please sign in to make it yours");
        setTimeout(() => {
          navigate("/sign-in");
        }, 1500);
        return;
      }

      if (!presetId) return;

      toast.loading("Making it yours...");

      await clonePresetTrip(user.id, presetId, formatTripDate(tripDate));

      toast.dismiss();

      navigate("/");
    } catch (error: any) {
      toast.dismiss();
      toast.error("Failed to make it yours");
      console.error(
        `[ERROR] : Error while cloning preset trip -> ${error.message}`
      );
    }
  }
  return (
    <Modal
      {...modalProps}
      classNames={{ closeButton: "bg-white" }}
      placement="center"
    >
      <ModalContent className="mx-10 p-5 flex flex-col items-center text-black gap-y-3">
        <h2 className="font-semibold">Almost there!</h2>
        <DatePicker
          label="Pick a date"
          value={tripDate}
          minValue={today(getLocalTimeZone())}
          onChange={setTripDate}
          fullWidth
        />
        <Button
          onClick={handleClonePreset}
          className="bg-black text-white"
          fullWidth
        >
          Done
        </Button>
      </ModalContent>
    </Modal>
  );
};

export default DateFormModal;
