import classNames from "classnames";
import React, { FC } from "react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

type Props = {
  onClick: () => void;
  mode: "prev" | "next";
};

const SwiperNavigationButton: FC<Props> = ({ onClick, mode }) => {
  const containerClass = classNames(
    "text-black absolute bg-gray-100 top-[40%] translate-y-[-50%] z-[100000] rounded-full w-[35px] h-[35px] flex justify-center items-center p-0",
    {
      "right-0": mode === "next",
      "left-0": mode === "prev",
    },
    {
      "translate-x-[50%]": mode === "next",
      "translate-x-[-50%]": mode === "prev",
    }
  );
  return (
    <button onClick={onClick} className={containerClass}>
      {mode === "next" ? (
        <FaChevronRight size={17} />
      ) : (
        <FaChevronLeft size={17} />
      )}
    </button>
  );
};

export default SwiperNavigationButton;
