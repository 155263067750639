import { ModalPlacement } from "../../models/component.model";

export function getCenterModalProps(): {
  placement: ModalPlacement;
  className: string;
} {
  return {
    placement: "center",
    className: "m-5 p-5",
  };
}
