import React, { FC } from "react";
import {
  MapViewProvider,
  useMapContext,
} from "../../../contexts/MapViewContext.tsx";
import { useFetchDestination } from "../../../hooks/fetch-data/useFetchDestination.ts";
import { PresetTrip, Trip } from "../../../models/trip.model.ts";
import LoadingPage from "../../common/LoadingPage.tsx";
import Mapbox from "../../map/Mapbox.jsx";

type Props = {
  trip: Trip | PresetTrip;
  currentSelectedDay: string;
  city: string;
};

const MapView: FC<Props> = ({ trip, currentSelectedDay, city }) => {
  return (
    <MapViewProvider
      trip={trip}
      currentSelectedDay={currentSelectedDay}
      city={city}
    >
      <main className="lg:h-full h-[390px] w-full flex gap-x-3 box-border overflow-hidden">
        <MapBackground />
      </main>
    </MapViewProvider>
  );
};

const MapBackground: FC = () => {
  const { destinations, city } = useMapContext();

  const {
    data: centerDestinationDetail,
    isFetching: isFetchingFirstDestination,
  } = useFetchDestination(city, destinations[0].place_id);

  if (isFetchingFirstDestination) {
    <LoadingPage message="Fetching destination..." />;
  }

  if (destinations.length <= 0)
    return (
      <main className="relative w-full h-[450px]">
        <div className="absolute top-[35%] translate-y-[-50%] left-1/2 w-[40px] h-[40px] translate-x-[-50%]">
          <div className="w-full h-full border-[4px] border-solid border-r-blue-500 animate-spin rounded-full "></div>
        </div>
      </main>
    );

  if (!centerDestinationDetail) return null;

  return (
    <>
      <aside className="relative w-full h-full rounded-3xl overflow-hidden">
        <Mapbox
          zoom={9}
          destinations={destinations}
          lat={centerDestinationDetail.coordinate?.lat}
          lng={centerDestinationDetail.coordinate?.lng}
          city={city}
        />
      </aside>
    </>
  );
};

export default MapView;
