import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import { EnvelopeIcon, KeyIcon } from "@heroicons/react/24/outline";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@nextui-org/react";
import { useUser } from "../../../hooks/useUser";
import { useHandleAuth } from "../../../hooks/useHandleAuth";

const CredentialSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
  password: z
    .string()
    .min(8, { message: "Password must be at least 8 characters long" }),
});

type Credential = z.infer<typeof CredentialSchema>;

export const SignUp = () => {
  const { user, loading } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (user) return navigate("/");
  }, [loading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Credential>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: zodResolver(CredentialSchema),
  });

  const { handleSignUp, navigateToSignIn } = useHandleAuth();

  return (
    <main className="bg-white flex flex-col items-start justify-center h-full w-full gap-y-8 overflow-hidden px-10">
      <div className="bg-primary w-[63px] h-[63px] rounded-full"></div>
      <h1 className="text-title w-[80%] font-medium leading-7 text-black">
        Sign in with Email
      </h1>
      <form
        onSubmit={handleSubmit(handleSignUp)}
        action=""
        className="w-full flex flex-col gap-y-4"
      >
        <label
          className={`input input-bordered flex items-center gap-2 ${
            errors.email ? "input-error" : ""
          }`}
        >
          <EnvelopeIcon className="size-6" />
          <input
            {...register("email")}
            type="text"
            className="grow w-2/3"
            placeholder="Your Email"
          />
        </label>
        {errors.password && (
          <span className="text-error text-sm">{errors.email?.message}</span>
        )}
        <label
          className={`input input-bordered flex items-center gap-2 ${
            errors.password ? "input-error" : ""
          }`}
        >
          <KeyIcon className="size-6" />
          <input
            {...register("password")}
            type="password"
            className="grow w-2/3"
            placeholder="Password"
          />
        </label>
        {errors.password && (
          <span className="text-error text-sm">{errors.password.message}</span>
        )}

        <Button
          className="bg-black text-white h-[56px] rounded-full mt-5"
          variant="solid"
          type="submit"
          fullWidth
        >
          Sign Up
        </Button>
      </form>
      <p className="text-sm text-center w-full">
        Already have an account?{" "}
        <span onClick={navigateToSignIn} className="underline text-black">
          Sign In
        </span>
      </p>
    </main>
  );
};

export default SignUp;
