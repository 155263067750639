import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { getTransportationDetail } from "../../helper/destination.helper";
import toast from "react-hot-toast";

const transportationIcons = {
  driving: "🚙",
  walking: "🚶",
};

const Transportation = ({ originPlaceId, targetPlaceId }) => {
  const [isError, setIsError] = useState(false);
  const { data: transportation, isPending: loading } = useQuery({
    queryKey: ["transportation", originPlaceId, targetPlaceId],
    queryFn: async () => {
      try {
        const detail = await getTransportationDetail(
          originPlaceId,
          targetPlaceId
        );
        return detail;
      } catch (error) {
        toast.error(error.message);
        setIsError(true);
        return null;
      }
    },
  });

  if (loading)
    return (
      <div className="bg-gray-300 w-full h-8 rounded-full animate-pulse"></div>
    );

  return (
    <>
      {transportation ? (
        <Detail transportation={transportation} />
      ) : (
        <p className="text-gray-400 text-base">
          {isError && "No transportation detail"}
        </p>
      )}
    </>
  );
};

const Detail = ({ transportation }) => {
  const { type, duration, distance } = transportation;
  return (
    <div className="flex items-center justify-between w-full h-[70px]">
      <LeftSection>
        <TransportationIcon type={type} />
        <Divider />
        <ul className="flex flex-col items-start">
          <p className="text-sm font-semibold">{duration}</p>
          <p className="text-sm text-gray-400">{distance}</p>
        </ul>
      </LeftSection>
    </div>
  );
};

const TransportationIcon = ({ type }) => {
  const icon = transportationIcons[type];
  return (
    <div className="bg-gray-100 p-2 w-[40px] h-[40px] rounded-full">{icon}</div>
  );
};

const Divider = () => {
  return <div className="h-full w-1 rounded-full bg-orange-300"></div>;
};

function LeftSection({ children }) {
  return <div className="flex h-full items-center gap-x-5">{children}</div>;
}

export default Transportation;
