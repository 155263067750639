import React, { useEffect, useMemo, useRef } from "react";
import { FaBloggerB, FaSignOutAlt, FaSignInAlt } from "react-icons/fa";
import { useNavigate } from "react-router";
import CONSTANTS from "../../../../constants";
import { useStripeCustomerInfo } from "../../../../hooks/useStripeCustomerInfo";
import { signOut } from "../../../../libs/services/auth.service";
import UpgradeToProButton from "../../UpgradeToProButton";
import { useUser } from "../../../../hooks/useUser";
import DropDownMenu from "./DropDownMenu";

function DesktopDropDown({ setShowSheet }) {
  const dropDownRef = useRef<HTMLUListElement | null>(null);
  const { user, loading } = useUser();
  const navigate = useNavigate();
  const { stripeCustomerInfo } = useStripeCustomerInfo();

  useEffect(() => {
    function handleClickOutSide(event: any) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setShowSheet((_: any) => false);
      }
    }

    document.addEventListener("mousedown", handleClickOutSide);

    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, [dropDownRef]);

  const menus = useMemo(
    () => [
      {
        title: "Blogs",
        icon: <FaBloggerB />,
        onClick: () => {
          window.location.href = CONSTANTS.ELEPLAN_BLOG_URL;
        },
      },
      {
        title: user ? "Sign Out" : "Sign In",
        icon: user ? <FaSignOutAlt /> : <FaSignInAlt />,
        onClick: async () => {
          if (!user) return navigate("/sign-in");
          await signOut();
          // refresh
          window.location.reload();
        },
      },
    ],
    [user]
  );

  if (loading) return null;

  return (
    <ul
      ref={dropDownRef}
      className="absolute flex flex-col z-50 bg-white m-0 p-0 w-[200px] right-0 top-0 translate-y-[70px] shadow-lg rounded-xl overflow-hidden"
    >
      {menus.map((menu, index) => {
        return (
          <DropDownMenu
            onClick={menu.onClick}
            key={menu.title}
            menu={menu}
            index={index}
          />
        );
      })}
      {!stripeCustomerInfo?.plan_active && <UpgradeToProButton />}
    </ul>
  );
}

export default DesktopDropDown;
