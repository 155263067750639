import { StarIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import React, { FC } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Pricing } from "../_type";

const PricingCard: FC<
  Pricing & {
    isSelected: boolean;
    onClick?: () => void;
  }
> = ({
  title,
  isBestValue,
  isDiscount,
  orignalPrice,
  netPrice,
  unit,
  description,
  isSelected,
  onClick,
}) => {
  const containerClass = classNames(
    "w-full flex flex-col items-start p-5 rounded-3xl gap-y-3 relative border-2 border-black box-border transition-all",
    isSelected ? "bg-black" : "bg-white",
    isSelected ? "text-white" : "text-black"
  );

  const bestValueLabelClass = classNames(
    "absolute top-0 right-0 px-5 py-2 text-black font-semibold rounded-bl-3xl rounded-tr-3xl flex gap-x-2 translate-x-[2px] translate-y-[-2px]",
    isSelected ? "bg-primary" : "bg-none"
  );
  return (
    <div onClick={onClick} className={containerClass}>
      {isBestValue ? (
        <div className={bestValueLabelClass}>
          <StarIcon className="size-5" />
          <p>Best value</p>
        </div>
      ) : null}
      <h2 className="text-lg">{title}</h2>
      <div className="w-full flex text-xl items-center gap-x-5 font-semibold">
        {isDiscount && (
          <>
            <p className="line-through">${orignalPrice.toFixed(2)}</p>
            <FaArrowRight />
          </>
        )}
        <p>
          ${netPrice.toFixed(2)} / {unit}
        </p>
      </div>
      <p>{description}</p>
    </div>
  );
};

export default PricingCard;
