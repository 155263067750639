import React from "react";
import { Chip } from "@nextui-org/react";
import classNames from "classnames";
import { FC } from "react";
import ScrollView from "../common/ScrollView";

type filterValue = string | null;

type FilterListProp = {
  currentValue: filterValue;
  onSelect: (value: filterValue) => void;
  options: filterValue[];
};

const FilterList: FC<FilterListProp> = ({
  currentValue,
  onSelect,
  options,
}) => {
  return (
    <ScrollView axis="horizontal" paddingX="none">
      <FilterItem
        option="All"
        onClick={() => onSelect(null)}
        isSelected={currentValue === null}
      />
      {options.map((option) => (
        <FilterItem
          key={option}
          option={option}
          onClick={() => onSelect(option)}
          isSelected={currentValue === option}
        />
      ))}
    </ScrollView>
  );
};

type FilterItemProp = {
  option: filterValue;
  onClick: () => void;
  isSelected: boolean;
};

const FilterItem: FC<FilterItemProp> = ({ option, onClick, isSelected }) => {
  const filterItemClass = classNames(
    "cursor-pointer transition-all bg-white border-2 border-solid border-black text-black font-bold",
    {
      "text-white bg-black": isSelected,
    }
  );
  return (
    <Chip className={filterItemClass} onClick={onClick}>
      {option}
    </Chip>
  );
};

export default FilterList;
