import { useQuery } from "@tanstack/react-query";
import { getErrorMessage } from "../../helper/util";
import { getDestination } from "../../libs/services/destination.service";

export function useFetchDestination(city: string, destinationId: string) {
  const query = useQuery({
    queryKey: ["fetch destination detail", city, destinationId],
    refetchOnWindowFocus: false,
    enabled: Boolean(destinationId) && Boolean(city),
    queryFn: async () => {
      try {
        return await getDestination(city, destinationId);
      } catch (error: any) {
        const errorMessage = getErrorMessage(error);

        throw new Error(errorMessage);
      }
    },
  });
  return query;
}
