import { LOCAL_STORAGE_KEYS } from "../../models/enums/localStorage.enum";

export function addUnsavedTripId(tripId: string) {
  if (!tripId) {
    return;
  }

  const previousUnsavedTripIds = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.unsavedTripIds) || "[]"
  );

  if (previousUnsavedTripIds.includes(tripId)) {
    return;
  }

  const updatedUnsavedTripIds = [...previousUnsavedTripIds, tripId];

  localStorage.setItem(
    LOCAL_STORAGE_KEYS.unsavedTripIds,
    JSON.stringify(updatedUnsavedTripIds)
  );
}
