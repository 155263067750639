import React from "react";
import { useNavigate } from "react-router";

const UpgradeToProButton = () => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => {
        navigate(`/pricing`);
      }}
      className="text-black border-2 border-solid border-primary focus:outline-none bg-white w-full mx-auto box-border text-sm mb-3 hover:bg-primary transition-all hover:outline-none hover:border-primary"
    >
      Upgrade to pro
    </button>
  );
};

export default UpgradeToProButton;
