import { itineraryManagementClient } from "../../configs/axios.config";
import { Trip, TripDetail } from "../../models/trip.model";

export async function createTrip(trip: any) {
  const { data } = await itineraryManagementClient.post<{
    message: string;
    tripId: string;
  }>(`/itinerary`, {
    trip,
  });

  return data;
}

export async function saveTripByUserId(userId: string, trip: any) {
  const { data } = await itineraryManagementClient.post<{
    message: string;
    tripId: string;
  }>(`/itinerary/save/userId/${userId}`, {
    trip,
  });

  return data;
}

export async function saveTripByTripId(tripId: string) {
  await itineraryManagementClient.post<{
    message: string;
  }>(`/itinerary/save/tripId/${tripId}`);
}

export async function fetchTripById(tripId: string): Promise<Trip | null> {
  const response = await itineraryManagementClient.get(
    `/itinerary?tripId=${tripId}`
  );
  return response.data.itinerary;
}

export async function fetchTripsByUserId(userId: string) {
  const response = await itineraryManagementClient.get(
    `/itinerary?userId=${userId}`
  );
  return response.data.itinerary;
}

export async function updateTripById(tripId: string, newItinerary: any) {
  await itineraryManagementClient.put(`/itinerary?tripId=${tripId}`, {
    newItinerary,
  });
}

export async function updateTripDetailById(
  tripId: string,
  updatedFields: Partial<Omit<TripDetail, "itinerary">>
) {
  await itineraryManagementClient.put(
    `/itinerary/detail/${tripId}`,
    updatedFields
  );
}

export async function deleteTripById(tripId: string) {
  await itineraryManagementClient.delete(`/itinerary?tripId=${tripId}`);
}
