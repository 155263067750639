import { Stack } from "@mui/material";
import { Button } from "@nextui-org/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "../../components/common/Container";
import LoadingPage from "../../components/common/LoadingPage";
import { useStripe } from "../../hooks/useStripe";
import { useStripeCustomerInfo } from "../../hooks/useStripeCustomerInfo";
import { Membership } from "../../models/classes/Membership.class";
import CancelButton from "./_components/CancelButton";
import PricingCard from "./_components/PricingCard";
import ValueProposition from "./_components/ValueProposition";
import { Pricing } from "./_type";

const isProduction = import.meta.env.VITE_ENV === "production";

const FEATURES = [
  "Time and Place Optimization  ⏱️ 🏞️",
  "Trip & Tips Ideas 💡 ✨",
  "Personalized Travel Plan 💖",
  "Automatic Journey Organizer ✍️🌟",
  "Suggest More Places to Visit 🗺️🌈",
  "Select the Best Route 🚗️ ️",
  "PDF. for save and sharing (offline)✅",
];

const PRICING_LIST: Pricing[] = [
  {
    title: "Annual Plan",
    isBestValue: true,
    isDiscount: true,
    orignalPrice: 72.0,
    netPrice: 35.55,
    description: "Only $2 / month (Billed annually)",
    unit: "year",
    priceId: isProduction
      ? "price_1Q8jbeKivMJ2TTzyIA41c3DJ"
      : "price_1Q2QurKivMJ2TTzynkClNCtE",
  },
  {
    title: "6-month Plan",
    isBestValue: false,
    isDiscount: true,
    orignalPrice: 36.0,
    netPrice: 19.99,
    description: "Billed every 6 months",
    unit: "6 months",
    priceId: isProduction
      ? "price_1Q8jaAKivMJ2TTzyKtCwIYON"
      : "price_1Q26P6KivMJ2TTzyaJejky4f",
  },
  {
    title: "3-month Plan",
    isBestValue: false,
    isDiscount: true,
    orignalPrice: 18.0,
    netPrice: 11.99,
    description: "Billed every 3 months",
    unit: "3 months",
    priceId: isProduction
      ? "price_1Q8jYNKivMJ2TTzyPhNQWkxP"
      : "price_1Q26P6KivMJ2TTzyaJejky4f",
  },
];

const PricingPage = () => {
  const [selectedPlanTitle, setSelectedPlanTitle] = useState(
    PRICING_LIST[0].title
  );
  const { user, loadingUserInfo, loadingStripeInfo, stripeCustomerInfo } =
    useStripeCustomerInfo();
  const { handleCheckoutPage } = useStripe(
    user,
    Membership.isValid(stripeCustomerInfo),
    PRICING_LIST.find((pricing) => pricing.title === selectedPlanTitle)
      ?.priceId ?? ""
  );

  if (loadingUserInfo || loadingStripeInfo) {
    return <LoadingPage message="Loading..." />;
  }

  return (
    <Container gap={4}>
      <CancelButton />
      <h1 className="text-3xl w-5/6 font-semibold">
        Enjoy a better experience ✨
      </h1>
      <Stack gap={2}>
        {FEATURES.map((feature, index) => (
          <ValueProposition key={index} text={feature} />
        ))}
      </Stack>
      <Stack gap={2}>
        {PRICING_LIST.map((pricing, index) => (
          <PricingCard
            key={index}
            {...pricing}
            isSelected={selectedPlanTitle === pricing.title}
            onClick={() => setSelectedPlanTitle(pricing.title)}
          />
        ))}
      </Stack>
      <footer className="w-full flex flex-col gap-y-3 mt-5">
        <Button
          className="min-h-[56px] rounded-full font-medium text-buttonTextColor text-base"
          color="primary"
          fullWidth
          onClick={handleCheckoutPage}
        >
          Try free 3-day trial
        </Button>
        <p className="text-sm font-medium text-center">
          <Link to="/policies/terms-of-service" className="underline">
            <span className="underline">Terms of service</span>
          </Link>
          {"  "}|{"  "}
          <Link to="/policies/privacy" className="underline">
            {" "}
            <span className="underline">Privacy policy</span>
          </Link>
        </p>
      </footer>
    </Container>
  );
};

export default PricingPage;
