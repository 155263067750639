import React, { FC, useEffect, useRef } from "react";
import { FaTrash } from "react-icons/fa";

type Props = {
  onDeleteItinerary: () => void;
  handleShowPopup: (value: boolean) => void;
};

function TripPopUp({ onDeleteItinerary, handleShowPopup }: Props) {
  const popUpRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    function handleClickOutSide(event: any) {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        handleShowPopup(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutSide);

    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, [popUpRef]);

  return (
    <ul
      ref={popUpRef}
      className="absolute top-12 right-3 bg-white rounded-lg p-2"
    >
      <PopUpMenu onDeleteItinerary={onDeleteItinerary} />
    </ul>
  );
}

function PopUpMenu({ onDeleteItinerary }) {
  return (
    <li
      onClick={async (e) => {
        e.stopPropagation();
        onDeleteItinerary();
      }}
      className="flex items-center gap-x-2"
    >
      <FaTrash />
      <p className="text-sm">Delete</p>
    </li>
  );
}

export default TripPopUp;
