import { Stack } from "@mui/material";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
} from "@nextui-org/react";
import React, { FC } from "react";
import { TModal } from "../../models/component.model";

const ReachMaximumModal: FC<TModal> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal
      className="mx-5 p-5"
      placement="center"
      classNames={{
        closeButton : "bg-white"
      }}
      hideCloseButton
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent>
        <ModalBody className="text-black">
          <Stack className="w-full h-full" alignItems={"center"} gap={1}>
            <h2 className="text-2xl font-semibold">Oops!</h2>
            <p className="text-center">
              Looks like you have reached the maximum number of free itineraries
            </p>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} fullWidth>
            <p className="text-buttonTextColor font-semibold">Cancel</p>
          </Button>
          <Button className="bg-primary" onClick={onConfirm} fullWidth>
            <p className="text-black font-semibold">Upgrade</p>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReachMaximumModal;
