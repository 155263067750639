import { useQuery } from "@tanstack/react-query";
import { fetchTripById } from "../libs/services/trip.service";
import { Trip } from "../models/trip.model";

export const useFetchTrip = (tripId: string | null) => {
  const query = useQuery<Trip | null>({
    queryKey: ["fetch trip", tripId],
    queryFn: async () => {
      if (!tripId) return null;
      return await fetchTripById(tripId);
    },
  });

  return query;
};
