import React, { FC } from "react";
import { FaFilePdf } from "react-icons/fa";
import { TButton } from "../../../models/component.model";

const PDFButton: FC<TButton> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="rounded-full overflow-hidden bg-white w-[45px] h-[45px] flex items-center justify-center absolute z-10 lg:right-1/2 lg:top-10 lg:mr-[20px] p-0 right-0 top-5 mr-[20px] shadow-xl"
    >
      <FaFilePdf className="text-primary" size={17} />
    </button>
  );
};

export default PDFButton;
