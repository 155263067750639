import React, { FC } from "react";
import { FaRegComment } from "react-icons/fa";
import { TButton } from "../../models/component.model";

const FeedbackButton: FC<TButton> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="fixed bottom-5 right-5 bg-primary rounded-full p-3 z-[10000] shadow-md"
    >
      <FaRegComment className="text-white" size={25} />
      <p className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] text-white">
        i
      </p>
    </button>
  );
};

export default FeedbackButton;
