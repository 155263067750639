import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
} from "react";
import { PresetTrip, Trip } from "../models/trip.model";
import { Destination } from "../models/destination";

type Props = {
  children: ReactNode;
  trip: Trip | PresetTrip;
  currentSelectedDay: string;
  city: string;
};

type ContextProps = {
  destinations: Destination[];
  city: string;
};

export const MapViewContext = createContext<ContextProps | null>(null);

export const MapViewProvider: FC<Props> = ({
  children,
  trip,
  currentSelectedDay,
  city,
}) => {
  const destinations = useMemo(() => {
    if (!currentSelectedDay || !trip) return [];
    const { itinerary } = trip.itinerary;
    return Object.values(itinerary[currentSelectedDay]);
  }, [trip, currentSelectedDay]);

  return (
    <MapViewContext.Provider
      value={{
        destinations,
        city,
      }}
    >
      {children}
    </MapViewContext.Provider>
  );
};

export const useMapContext = () => {
  const context = useContext(MapViewContext);
  if (!context) {
    throw new Error("useMapContext must be used within a MapViewProvider");
  }
  return context;
};
