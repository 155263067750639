import { z } from "zod";

export const FeedbackSchema = z.object({
  id: z.number(),
  created_at: z.string(),
  score: z
    .number()
    .max(4, { message: "Score must be between 0 and 4" })
    .min(0, { message: "Score must be between 0 and 4" }),
  comment: z.string().optional(),
  user_id: z.string().nullable().optional(),
});

export type Feedback = z.infer<typeof FeedbackSchema>;
