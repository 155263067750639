import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
} from "@nextui-org/react";
import React, { FC } from "react";
import { CustomModal } from "../../../models/component.model";
import { Stack } from "@mui/material";
import { NextUIButtonColor } from "../../../models/misc.model";

const ConfirmActionModal: FC<
  CustomModal<{
    title: string;
    description: string;
    confirmButtonLabel?: string;
    confirmButtonColor?: NextUIButtonColor;
    confirmButtonTextColor?: string;
  }>
> = ({
  title,
  isOpen,
  description,
  confirmButtonLabel = "Delete",
  confirmButtonColor = "danger",
  confirmButtonTextColor = "white",
  onClose,
  onConfirm,
}) => {
  return (
    <Modal
      className="mx-5 text-black"
      placement="center"
      classNames={{
        closeButton: "bg-white",
      }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent>
        <ModalBody className="py-5">
          <Stack alignItems={"center"} gap={1}>
            <h2 className="text-2xl font-semibold">{title}</h2>
            <p className="text-center">{description}</p>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} fullWidth>
            <p className="text-buttonTextColor font-semibold">Cancel</p>
          </Button>
          <Button color={confirmButtonColor} onClick={onConfirm} fullWidth>
            <p className={`text-${confirmButtonTextColor} font-semibold`}>
              {confirmButtonLabel}
            </p>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmActionModal;
