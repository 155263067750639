import { getLocalStorageItem, setLocalStorageItem } from "../../helper/util";

export function removedUnsavedTripIds(tripId: string) {
  const previousUnsavedTripIds =
    getLocalStorageItem<string[]>("unsavedTripIds") || [];

  const newUnsavedTripIds = previousUnsavedTripIds.filter(
    (id) => id !== tripId
  );

  setLocalStorageItem("unsavedTripIds", newUnsavedTripIds);
}
