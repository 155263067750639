import React, { FC, ReactNode, useRef } from "react";
import { Swiper } from "swiper/react";
import SwiperNavigationButton from "../specific/swiper/SwiperNavigationButton";

type CarouselProps = {
  children: ReactNode;
};

const Carousel: FC<CarouselProps> = ({ children }) => {
  const swiperRef = useRef<any>(null);

  function handleNextTrip() {
    swiperRef.current?.slideNext();
  }

  function handlePrevTrip() {
    swiperRef.current?.slidePrev();
  }
  return (
    <div className="relative w-full">
      <SwiperNavigationButton onClick={handlePrevTrip} mode="prev" />
      <SwiperNavigationButton onClick={handleNextTrip} mode="next" />
      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        className="h-full w-full"
      >
        {children}
      </Swiper>
    </div>
  );
};

export default Carousel;
