import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { supabase } from "../configs/db.config";
import { signIn, signUp } from "../libs/services/auth.service";

export function useHandleAuth(redirectUrl?: string) {
  const navigate = useNavigate();

  async function handleSignInWithEmail(credentials: {
    email: string;
    password: string;
  }) {
    const { email, password } = credentials;
    try {
      toast.loading("Signing in...");
      await signIn({ email, password });
      toast.dismiss();
      toast.success("Signed in successfully");
      setTimeout(() => {
        window.location.assign(redirectUrl || "/");
      }, 1500);
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  async function handleForgotPassword(email: string) {
    try {
      if (!email) throw new Error("Please enter your email address");
      toast.loading("Sending reset link...");
      await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${process.env.OAUTH_REDIRECT_URL}`,
      });
      toast.dismiss();
      toast.success("Reset link sent to your email");
    } catch (error: any) {
      toast.dismiss();
      toast.error(error.message);
    }
  }

  async function handleSignUp(credentials: {
    email: string;
    password: string;
  }) {
    try {
      const { email, password } = credentials;
      toast.loading("Signing up...");
      await signUp({ email, password });
      toast.dismiss();
      toast.success("Please verify your Email in the mail box");
      setTimeout(() => {
        navigate("/sign-in");
      }, 1500);
    } catch (error: any) {
      toast.dismiss();
      toast.error(error.message);
    }
  }

  function navigateToSignUp() {
    navigate("/sign-up");
  }

  function navigateToSignIn() {
    navigate("/sign-in/email");
  }

  return {
    handleSignInWithEmail,
    handleForgotPassword,
    handleSignUp,
    navigateToSignUp,
    navigateToSignIn,
  };
}
