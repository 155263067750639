import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useItineraryPageContext } from "../../../contexts/ItineraryPageContext";

const BackButton = ({ onBackHome, currentSelectedDay }) => {

  return (
    <div className="absolute z-10 p-[20px]">
      <button
        className={`w-[45px] h-[45px] p-0 rounded-full flex items-center justify-center ${
          currentSelectedDay ? "shadow-xl shadow-gray-400" : ""
        } bg-white`}
        onClick={onBackHome}
      >
        <FaChevronLeft
          size={17}
          className="text-[#FAD127] font-normal translate-x-[-2px]"
        />
      </button>
    </div>
  );
};

export default BackButton;
