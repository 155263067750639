import { CalendarDate, getLocalTimeZone } from "@internationalized/date";
import { Empty } from "../../models/misc.model";
import { DATE_FORMAT } from "../../models/enums/date.enum";

const DAY = 24 * 60 * 60 * 1000;

export function formatDay(
  originalDayFormat: DATE_FORMAT,
  day: string | Empty,
  separator = "-"
): string {
  if (!day) return "Invalid day";

  let date: string, month: string, year: string;

  const splitDay = day.split(separator);

  switch (originalDayFormat) {
    case "mm-dd-yyyy":
      date = splitDay[1];
      month = splitDay[0];
      year = splitDay[2];
      break;
    case "dd-mm-yyyy":
      return day;
    case "yyyy-mm-dd":
      date = splitDay[2];
      month = splitDay[1];
      year = splitDay[0];
      break;
    default:
      return "Invalid originalDayFormat";
  }

  return `${date}/${month}/${year}`;
}

export function formatTripCardDate(dateFromItinerary: string | Empty): string {
  // Ex : 2024-08-21
  if (!dateFromItinerary) return "Invalid date";
  const [year, month, day] = dateFromItinerary.split("-");
  return `${day}/${month}/${year}`;
}

export function addDaysForTripCardDate(
  dateFromItinerary: string | Empty,
  days: number,
  format: "string" | "date"
): string | Date {
  // Ex : 2024-08-21
  if (typeof dateFromItinerary !== "string") return "Invalid dateFromItinerary";

  if (typeof days !== "number") return "Invalid days";

  const date = new Date(dateFromItinerary);

  date.setDate(date.getDate() + days);

  const newYear = date.getFullYear();

  const newMonth = date.getMonth() + 1;

  const newDay = date.getDate();

  return format === "string" ? `${newDay}/${newMonth}/${newYear}` : date;
}

export function getTimeDifferenceForTripCard(
  dateFromItinerary: string | Empty,
  targetDate: Date
): number {
  if (!dateFromItinerary) {
    console.error("Invalid dateFromItinerary");
    return 0;
  }

  const date = new Date(dateFromItinerary);

  const difference = date.getTime() - targetDate.getTime();

  const daysDifference = Math.floor(difference / DAY);

  return daysDifference;
}

export function getTimeDifferenceInternationalTimes(
  startDate: CalendarDate,
  endDate: CalendarDate
) {
  const difference =
    endDate.toDate(getLocalTimeZone()).getTime() -
    startDate.toDate(getLocalTimeZone()).getTime();
  const daysDifference = Math.floor(difference / DAY);
  return daysDifference;
}
