import { User } from "@supabase/supabase-js";
import React, { FC } from "react";
import FeedbackModal from "./FeedbackModal";
import FeedbackButton from "./FeedbackButton";
import { useModal } from "../../hooks/useModal";

type Props = {
  user?: User | null;
};

const FeedbackSection: FC<Props> = ({ user }) => {
  const {
    isOpen: isShowFeedback,
    handleClose: onCloseFeedbackModal,
    handleOpen: onOpenFeedbackModal,
  } = useModal();
  return (
    <>
      <FeedbackModal
        isOpen={isShowFeedback}
        onClose={onCloseFeedbackModal}
        userId={user?.id}
      />
      <FeedbackButton onClick={onOpenFeedbackModal} />
    </>
  );
};

export default FeedbackSection;
