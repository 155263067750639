import { useSearchParams } from "react-router-dom";

export function useGetSearchParams<D>(
  params: { key: string; serializer?: (...props: any) => any }[]
): D[] {
  const [searchParams] = useSearchParams();
  return params.map((param) => {
    const { key, serializer } = param;
    const value = searchParams.get(key);
    return serializer ? serializer(value) : value;
  });
}
