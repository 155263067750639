import React, { FC, useMemo } from "react";
import Collapsable from "../../../Collapsable";
import ListView from "../../../ListView.";
import { TripDetail } from "../../../../models/trip.model";
import DestinationEdit from "../../../DestinationEdit";
import { Empty } from "../../../../models/misc.model";
import Transportation from "../../../itinerary/Transportation";
import { GoogleDestinationSearchBar } from "../../../GoogleDestinationSearchBar";
import SuggestedDestinations from "../../../../routes/itinerary/_components/SuggestedDestinations";
import { updateTripById } from "../../../../libs/services/trip.service";
import {
  addDestination,
  updateItineraryTime,
} from "../../../../helper/itinerary.helper";
import toast from "react-hot-toast";
import { Destination } from "../../../../models/destination";
import { Trip } from '../../../../models/classes/Trip.class';

type Props = {
  index: number;
  isExpanded: boolean;
  onSetCurrentSelectedDay: any;
  day: string;
  tripDetail: TripDetail;
  isPreset: boolean;
  onUpdateItinerary: any;
  selectedDestination: Destination | Empty;
  onSelectDestination: any;
  tripId: string;
  isPremiumUser: boolean;
  isTripInThePast : boolean;
};

const Day: FC<Props> = ({
  index,
  isExpanded,
  onSetCurrentSelectedDay,
  day,
  tripDetail,
  isPreset,
  onUpdateItinerary,
  selectedDestination,
  onSelectDestination,
  tripId,
  isPremiumUser,
  isTripInThePast,
}) => {
  const destinations = Object.values(tripDetail.itinerary[day]);
  
  async function handleAddGoogleDestination(
    destination: Destination,
    currentDay: string
  ) {
    try {
      if (!tripDetail) return;

      if (!destination) return toast.error("Please select a destination");

      if (!currentDay) return toast.error("Please select a day");

      // const doesDestinationExist = Object.values(
      //   tripDetail.itinerary[currentDay]
      // ).find((dest: any) => dest.place_id === destination.place_id);

      // if (doesDestinationExist)
      //   return toast.error("You have already added this place");

      const updatedItinerary = addDestination(
        tripDetail.itinerary,
        destination,
        currentDay
      );

      toast.loading("Adding the destination...");

      await updateTripById(tripId as string, updatedItinerary);

      onUpdateItinerary(updatedItinerary);

      toast.dismiss();

      toast.success("Added the destination");
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function handleUpdateDestinationTime(
    currentDay: string,
    originTime: string,
    targetTime: string
  ) {
    try {
      if (!tripDetail) return;

      const updatedItinerary = updateItineraryTime(
        tripDetail.itinerary,
        currentDay,
        originTime,
        targetTime
      );

      await updateTripById(tripId as string, updatedItinerary);

      onUpdateItinerary(updatedItinerary);
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <>
      <Collapsable
        index={index}
        isExpanded={isExpanded}
        onClick={() => {
          onSetCurrentSelectedDay((prev) => {
            return prev === day ? null : day;
          });
        }}
        title={day}
      >
        <ListView align="flex-start">
          {destinations.map((destination: any, index) => {
            const destinations: any[] = Object.values(
              tripDetail.itinerary[day]
            );

            return (
              <div
                className="flex flex-col gap-y-3 w-full"
                key={destination.name_place}
              >
                <DestinationEdit
                  itinerary={tripDetail.itinerary}
                  isTrashVisible={!(isTripInThePast || isPreset)}
                  onUpdateItinerary={onUpdateItinerary}
                  key={destination.name_place}
                  editAble={!(isTripInThePast || isPreset)}
                  currentDay={day}
                  onUpdateItineraryTime={handleUpdateDestinationTime}
                  time={Object.keys(tripDetail.itinerary[day])[index]}
                  destination={destination}
                  tripId={tripId}
                  onClick={() =>
                    onSelectDestination(
                      selectedDestination === destination ? null : destination
                    )
                  }
                />

                {index < destinations.length - 1 ? (
                  isTripInThePast ? null : !isPreset ? (
                    <Transportation
                      originPlaceId={destination.place_id}
                      targetPlaceId={destinations[index + 1].place_id}
                    />
                  ) : null
                ) : null}
              </div>
            );
          })}
        </ListView>
        {isTripInThePast || isPreset ? null : (
          <div className="my-[15px]">
            <GoogleDestinationSearchBar
              targetCountry={tripDetail.country}
              currentDay={day}
              onAddDestination={handleAddGoogleDestination}
            />
          </div>
        )}
        {isTripInThePast || isPreset ? null : (
          <div className="mt-5">
            <SuggestedDestinations
              currentDay={day}
              itinerary={tripDetail}
              isPremiumUser={isPremiumUser}
            />
          </div>
        )}
      </Collapsable>
    </>
  );
};

export default Day;
