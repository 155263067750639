import { Stack } from "@mui/material";
import React, { FC } from "react";
import { FaCheck, FaCheckCircle } from "react-icons/fa";

type Props = {
  text: string;
};

const ValueProposition: FC<Props> = ({ text }) => {
  return (
    <Stack direction={"row"} alignItems={"center"} gap={1.5}>
      <FaCheckCircle size={22} />
      <p className="text-sm font-semibold">{text}</p>
    </Stack>
  );
};

export default ValueProposition;
