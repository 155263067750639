import { padTime } from "./util";

// update time of a destination in a specified day
export function updateItineraryTime(itinerary, day, originTime, targetTime) {
  const deepItinerary = JSON.parse(JSON.stringify(itinerary));

  delete deepItinerary[day][originTime];

  deepItinerary[day][targetTime] = itinerary[day][originTime];

  deepItinerary[day] = Object.fromEntries(
    Object.entries(deepItinerary[day]).sort((first, second) => {

      const timeFirst = first[0];

      const timeSecond = second[0];

      return timeFirst.localeCompare(timeSecond);
    })
  );

  return deepItinerary;
}

function isObjectSame(object1, object2) {
  return JSON.stringify(object1) === JSON.stringify(object2);
}

export function deleteDestination(itinerary, targetDay, targetDestination) {
  let destinationIndex;

  const deepItinerary = JSON.parse(JSON.stringify(itinerary));

  const entries = Object.entries(deepItinerary);

  entries.map((currentDay) => {

    if (currentDay[0] === targetDay) {
      const destinations = currentDay[1];

      const destinationEntries = Object.entries(destinations);

      const index = destinationEntries.findIndex((destination) =>
        isObjectSame(destination[1], targetDestination)
      );
      destinationIndex = index;
    }
  });

  const keyTime = Object.keys(deepItinerary[targetDay])[destinationIndex];

  delete deepItinerary[targetDay][keyTime];

  return deepItinerary;
}

export function addDestination(itinerary, newDestination, day) {
  const rightNow = new Date();

  const [hour, minute] = [rightNow.getHours(), rightNow.getMinutes()];

  const [formattedHour, formattedMinutes] = padTime(hour, minute);

  let formattedTime = `${formattedHour}:${formattedMinutes}`;

  // If the time is already taken, increment the time by 1 minute
  while (Object.keys(itinerary[day]).includes(formattedTime)) {
    const [h, m] = formattedTime.split(":").map((time) => parseInt(time));
    if (m === 59) {
      formattedTime = `${h + 1}:00`;
    } else {
      formattedTime = `${h}:${m + 1}`;
    }
  }

  // Deep copy the itinerary
  const deepItinerary = JSON.parse(JSON.stringify(itinerary));
  const targetDay = deepItinerary[day];
  const result = {
    ...deepItinerary,
    [day]: {
      ...targetDay,
      [formattedTime]: {
        ...newDestination,
        special_events:
          Object.keys(newDestination).includes("special_events") &&
          newDestination["special_events"]
            ? newDestination["special_events"].split(",")
            : "",
      },
    },
  };
  return result;
}
