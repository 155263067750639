import { CircularProgress } from "@nextui-org/react";
import React, { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { getLocalStorageItem } from "../../helper/util";
import { useUser } from "../../hooks/useUser";
import { saveTripByTripId } from "../../libs/services/trip.service";
import { LOCAL_STORAGE_KEYS } from "../../models/enums/localStorage.enum";
import { removedUnsavedTripIds } from "./helper";
import LoadingPage from "../../components/common/LoadingPage";

const SaveItineraryPage = () => {
  const unsavedTripIds = useRef<string[] | null>(
    getLocalStorageItem<string[]>(LOCAL_STORAGE_KEYS.unsavedTripIds)
  );
  const navigate = useNavigate();

  const { user, loading } = useUser();

  useEffect(() => {
    async function saveAllTrips() {
      if (!unsavedTripIds.current || loading || !user) return;

      for (const tripId of unsavedTripIds.current) {
        try {
          await saveTripByTripId(tripId);
        } catch (error: any) {
          console.error(`[ERROR] : ${error.message}`);
        }
        removedUnsavedTripIds(tripId);
      }

      unsavedTripIds.current = getLocalStorageItem<string[]>(
        LOCAL_STORAGE_KEYS.unsavedTripIds
      );

      toast.success("Itineraries saved successfully!");

      setTimeout(() => {
        navigate("/");
      }, 1500);
    }

    saveAllTrips();
  }, [unsavedTripIds.current, loading, user?.id]);

  if (loading) {
    return <LoadingPage />;
  }

  if (!user) {
    navigate("/sign-in");
    return null
  }

  return (
    <main className="h-lvh w-full flex items-center justify-center">
      <CircularProgress label="Saving your itineraries..." />
    </main>
  );
};

export default SaveItineraryPage;
