import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { FC } from "react";
import { Destination } from "../../../models/destination";
import DestinationItem from "./DestinationItem";
import Header from "./Header";
import LoadingDestinations from "./LoadingDestinations";
import { CardContent, Stack } from "@mui/material";
import { Button, Card, CardBody } from "@nextui-org/react";
import { FaLock } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useFetchSuggestedDestinations } from "../../../hooks/fetch-data/useFetchSuggestedDestinations";

type Props = {
  itinerary: any;
  currentDay: string;
  isPremiumUser: boolean;
};

const SuggestedDestinations: FC<Props> = ({
  itinerary,
  currentDay,
  isPremiumUser,
}) => {
  const navigate = useNavigate();
  const {
    data: destinations,
    isPending,
    isError,
    error,
    refetch,
    isRefetching,
  } = useFetchSuggestedDestinations(itinerary.city, itinerary.categories);

  if (isError)
    return (
      <h1 className="text-sm">
        {error.message ?? "Could not suggest you more destinations"}
      </h1>
    );

  const isLoading = isPending || isRefetching;

  return (
    <>
      <Header onRefresh={() => refetch()} />
      {isLoading ? (
        <div className="flex gap-x-3 w-full overflow-x-scroll">
          {Array.from({ length: 5 }, (_, index) => {
            return <LoadingDestinations key={index} />;
          })}
        </div>
      ) : isPremiumUser ? (
        <div className="w-full rounded-lg flex items-center justify-between gap-4 overflow-x-scroll my-2">
          {destinations
            ? destinations.map((destination: Destination, index: number) => {
                return (
                  <DestinationItem
                    key={`${destination.place_id}`}
                    currentDay={currentDay}
                    destination={destination}
                  />
                );
              })
            : null}
        </div>
      ) : (
        <Card className="m-3">
          <CardContent>
            <CardBody>
              <Stack alignItems={"center"} gap={2}>
                <h1 className="text-sm text-center font-semibold">
                  Unlock premium to use this feature
                </h1>
                <Button
                  onClick={() => navigate("/pricing")}
                  color="primary"
                  isIconOnly
                >
                  <FaLock />
                </Button>
              </Stack>
            </CardBody>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default SuggestedDestinations;
