import { loadStripe } from "@stripe/stripe-js";
import { User } from "@supabase/supabase-js";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { paymentClient } from "../configs/axios.config";

export function useStripe(
  user: User | null,
  isPremiumUser: boolean,
  priceId?: string,
) {
  const navigate = useNavigate();

  async function handleCheckoutPage() {
    try {
      if (!priceId) {
        toast.error("Price id is missing");
        return;
      }

      if (!user) {
        toast.dismiss();
        setTimeout(() => {
          navigate("/login");
        }, 1500);
        throw new Error("Please signin before upgrading to pro");
      }

      if (isPremiumUser){
        toast.dismiss();
        throw new Error("You are already a premium user");
      }

      toast.loading("Redirecting to checkout page...");

      const stripe = await loadStripe(
        import.meta.env.VITE_STRIPE_PUBLIC_KEY as string
      );

      if (!user.id || !user.email) {
        toast.error("User id or email is missing");
        return;
      }

      const payload = {
        priceId,
        userId: user.id,
        email: user.email,
      };

      const response = await paymentClient.post("/stripe/checkout", payload);

      const sessionId = response.data.id;

      if (!sessionId) {
        toast.error("Failed to create checkout session");
        return;
      }

      toast.dismiss();
      await stripe?.redirectToCheckout({ sessionId: sessionId });
    } catch (error: any) {
      let errorMessage: string = error.message;

      if (isAxiosError(error)) {
        errorMessage = error.response?.data.message;
      }

      toast.dismiss();

      toast.error(errorMessage);
    }
  }

  async function handleNavigateCustomerPortal() {
    try {
      if (!user) {
        toast.error("Please signin to manage your subscription");
        return;
      }

      toast.loading("Redirecting to customer portal...");

      const response = await paymentClient.get(
        `/stripe/user-portal/${user.id}`
      );

      toast.dismiss();

      if (response.status !== 200) {
        toast.error("Failed to redirect to customer portal");
        return;
      }

      const url = response.data.url;

      window.location.assign(url);
    } catch (error: any) {
      toast.dismiss();
      toast.error(error.message);
    }
  }

  return { handleCheckoutPage, handleNavigateCustomerPortal };
}
