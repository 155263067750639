import { MapIcon } from "@heroicons/react/24/outline";
import { Time } from "@internationalized/date";
import { Stack } from "@mui/material";
import { TimeInput } from "@nextui-org/react";
import classNames from "classnames";
import React, { FC, MouseEvent, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { FaTrashAlt } from "react-icons/fa";
import { getDestinationMapUrl } from "../helper/destination.helper";
import { deleteDestination } from "../helper/itinerary.helper";
import { extractDestinationTime, padTime } from "../helper/util";
import { useModal } from "../hooks/useModal";
import { updateTripById } from "../libs/services/trip.service";
import { TButton } from "../models/component.model";
import { Destination } from "../models/destination";
import { Itinerary } from "../models/trip.model";
import ConfirmActionModal from "./common/modal/ConfirmActionModal";
import { Tag } from "./itinerary/Tag";

type Props = {
  destination: Destination;
  time: string;
  editAble?: boolean;
  onUpdateItineraryTime: any;
  currentDay: string;
  onUpdateItinerary: any;
  isTrashVisible?: boolean;
  displayTime?: boolean;
  itinerary: Itinerary;
  tripId: string;
};

const DestinationEdit: FC<Props & TButton> = ({
  destination,
  time,
  editAble = true,
  onUpdateItinerary,
  onUpdateItineraryTime,
  currentDay,
  isTrashVisible = false,
  displayTime = true,
  itinerary,
  tripId,
  onClick,
}) => {
  const [destinationTime, setDestinationTime] = useState<string>(time);

  const extractedDestinationTime = extractDestinationTime(destinationTime);

  const timeInputRef = useRef<HTMLInputElement | null>(null);

  const {
    isOpen: isShowDeleteModal,
    handleClose: onCloseDeleteModal,
    handleOpen: onOpenDeleteModal,
  } = useModal();

  function onTimeInput(timeValue: Time) {
    try {
      const { hour, minute } = timeValue;

      const [paddedHour, paddedMinute] = padTime(hour, minute);

      const allDays = Object.keys(itinerary[currentDay]);

      const formattedTime = `${paddedHour}:${paddedMinute}`;

      const doesCurrentTimeOverlap = allDays.some((time) => {
        return time === formattedTime;
      });

      if (doesCurrentTimeOverlap)
        return toast.error("Cannot have two destinations at the same time");

      setDestinationTime(formattedTime);

      onUpdateItineraryTime(currentDay, time, formattedTime);
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function handleDeleteDestination() {
    try {
      const doesCurrentHaveOneDestination =
        Object.keys(itinerary[currentDay]).length <= 1;

      if (doesCurrentHaveOneDestination)
        throw new Error("Cannot delete the last destination");

      toast.loading("Deleting destination...");

      const updatedItinerary = deleteDestination(
        itinerary,
        currentDay,
        destination
      );

      await updateTripById(tripId, updatedItinerary);

      setTimeout(() => {
        toast.dismiss();

        toast.success("Successfully deleted the destination");

        onUpdateItinerary(updatedItinerary);
      }, 1000);
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <>
      <ConfirmActionModal
        isOpen={isShowDeleteModal}
        onClose={onCloseDeleteModal}
        onConfirm={handleDeleteDestination}
        title="Oops!"
        description="Are you sure you want to delete the destination?"
      />
      <div
        onClick={onClick}
        className="flex gap-5 items-center relative w-full min-h-[60px]"
      >
        {displayTime ? (
          <TimeInput
            size="sm"
            classNames={{
              base: "w-max rotate-[-90deg]",
              inputWrapper: "bg-white shadow-none",
              input: "text-black",
            }}
            aria-label="Destination time input"
            label={null}
            hourCycle={24}
            ref={timeInputRef}
            value={
              extractedDestinationTime
                ? new Time(
                    Number(extractedDestinationTime[0]),
                    Number(extractedDestinationTime[1])
                  )
                : null
            }
            variant="flat"
            isDisabled={!editAble}
            onChange={onTimeInput}
          />
        ) : null}
        <Detail displayTime={displayTime}>
          <h4 style={{ margin: "0", fontSize: "13px", fontWeight: "600" }}>
            {destination.name_place}
          </h4>
          {Boolean(destination.category) ? (
            destination.category?.includes(",") ? (
              <Stack
                sx={{
                  maxWidth: "100%",
                }}
                spacing={1}
                direction={"row"}
              >
                {destination.category.split(",").map((tag) => {
                  return <Tag key={tag} content={tag} />;
                })}
              </Stack>
            ) : (
              <Tag content={destination.category} />
            )
          ) : null}
        </Detail>
        {isTrashVisible && <DeleteButton onClick={onOpenDeleteModal} />}
        <GoogleMapButton destinationName={destination.name_place} />
      </div>
    </>
  );
};

type GoogleMapButtonProps = {
  destinationName: string;
};

const GoogleMapButton: FC<GoogleMapButtonProps> = ({ destinationName }) => {
  const googleMapUrl = getDestinationMapUrl(destinationName);
  return (
    <MapIcon
      className="size-5 absolute bottom-0 right-0"
      onClick={(e: MouseEvent<SVGSVGElement>) => {
        e.stopPropagation();
        window.location.assign(googleMapUrl);
      }}
    />
  );
};

const DeleteButton: FC<TButton> = ({ onClick }) => {
  return (
    <FaTrashAlt
      className="absolute top-0 right-0 text-primary cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) {
          onClick();
        }
      }}
    />
  );
};

const Detail = ({ children, displayTime }) => {
  const detailClass = classNames(
    "flex",
    "flex-col",
    "gap-[5px]",
    "justify-center",
    "ml-[15px]",
    {
      "transform translate-x-[-40px]": displayTime,
      "transform translate-x-0": !displayTime,
    }
  );

  return <div className={detailClass}>{children}</div>;
};

export default DestinationEdit;
