import { Stack } from "@mui/material";
import React, { FC } from "react";
import { Tag } from "../itinerary/Tag";

type Props = {
  categories: string[];
};

const DestinationCategories: FC<Props> = ({ categories }) => {
  return (
    <Stack direction={"row"} gap={1}>
      {categories?.map((category) => <Tag key={category} content={category} />)}
    </Stack>
  );
};

export default DestinationCategories;
