import React, { FC, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Destination } from "../../../models/destination";
import { Empty } from "../../../models/misc.model";
import { TripDetail } from "../../../models/trip.model";
import BottomSheetDestination from "../../itinerary/destination/BottomSheetDestination";
import Day from "./_components/Day";

type Props = {
  tripDetail: TripDetail | Empty;
  tripId: string;
  handleUpdateItinerary: any;
  onSetCurrentSelectedDay: any;
  currentSelectedDay: string;
  isPremiumUser: boolean;
  city: string;
  isTripInThePast: boolean;
  isPreset?: boolean;
};

const DayList: FC<Props> = ({
  tripDetail,
  tripId,
  handleUpdateItinerary,
  onSetCurrentSelectedDay,
  isPremiumUser,
  currentSelectedDay,
  city,
  isTripInThePast,
  isPreset = false,
}) => {
  const [selectedDestination, setSelectedDestination] =
    useState<Destination | null>(null);

  if (!tripDetail) return null;

  const days = Object.keys(tripDetail.itinerary);

  return (
    <div className="m-0 px-[10px] lg:px-[20px] h-screen overflow-y-scroll p-0 text-black">
      {selectedDestination ? (
        <BottomSheet
          sibling={
            <div
              className="custom-backdrop"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedDestination((_) => null);
              }}
            />
          }
          open={Boolean(selectedDestination)}
          onDismiss={() => {
            setSelectedDestination((_) => null);
          }}
          maxHeight={500}
        >
          {selectedDestination ? (
            <BottomSheetDestination
              city={city}
              destinationId={selectedDestination.place_id}
            />
          ) : null}
        </BottomSheet>
      ) : null}
      <ul className="flex flex-col gap-y-4 w-full m-0 p-0">
        {days.map((day, index) => (
          <Day
            key={day}
            index={index}
            isExpanded={day === currentSelectedDay}
            onSetCurrentSelectedDay={onSetCurrentSelectedDay}
            day={day}
            tripDetail={tripDetail}
            isPreset={isPreset}
            onUpdateItinerary={handleUpdateItinerary}
            selectedDestination={selectedDestination}
            onSelectDestination={setSelectedDestination}
            tripId={tripId}
            isPremiumUser={isPremiumUser}
            isTripInThePast={isTripInThePast}
          />
        ))}
      </ul>
    </div>
  );
};

export default DayList;
