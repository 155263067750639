import { EnvelopeIcon, HomeIcon, KeyIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@nextui-org/react";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useHandleAuth } from "../../../../hooks/useHandleAuth";
import { useQueryState } from "nuqs";

const CredentialSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
  password: z
    .string()
    .min(8, { message: "Password must be at least 8 characters long" }),
});

type Credential = z.infer<typeof CredentialSchema>;

const SignInWithEmailPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Credential>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: zodResolver(CredentialSchema),
  });

  const [redirectUrl, _] = useQueryState("redirect", {
    defaultValue: "",
  });

  const { handleSignInWithEmail, handleForgotPassword, navigateToSignUp } =
    useHandleAuth(redirectUrl);

  return (
    <main className="bg-white flex flex-col items-start justify-center h-full w-full gap-y-8 overflow-hidden px-10">
      {/* <button className="p-0 w-[45px] h-[45px] bg-gray-100 flex items-center justify-center">
        <HomeIcon className="size-7" />
      </button> */}
      <div className="bg-primary w-[63px] h-[63px] rounded-full"></div>
      <h1 className="text-title w-[80%] font-medium leading-7 text-black">
        Sign in with Email
      </h1>
      <form
        onSubmit={handleSubmit(handleSignInWithEmail)}
        action=""
        className="w-full flex flex-col gap-y-4"
      >
        <label
          className={`input input-bordered flex items-center gap-2 ${
            errors.email ? "input-error" : ""
          }`}
        >
          <EnvelopeIcon className="size-6" />
          <input
            {...register("email")}
            type="text"
            className="grow w-2/3 bg-white"
            placeholder="Your Email"
          />
        </label>
        {errors.password && (
          <span className="text-error text-sm">{errors.email?.message}</span>
        )}
        <label
          className={`input input-bordered flex items-center gap-2 ${
            errors.password ? "input-error" : ""
          }`}
        >
          <KeyIcon className="size-6" />
          <input
            {...register("password")}
            type="password"
            className="grow w-2/3 bg-white"
            placeholder="Password"
          />
        </label>
        {errors.password && (
          <span className="text-error text-sm">{errors.password.message}</span>
        )}
        <p
          className="text-sm font-semibold my-2 text-right"
          onClick={() => handleForgotPassword(watch().email)}
        >
          Forgot your password?
        </p>
        <Button
          className="bg-black text-white h-[56px] rounded-full"
          variant="solid"
          type="submit"
          fullWidth
        >
          Sign In
        </Button>
      </form>
      <p className="text-sm text-center w-full">
        Don't have any account?{" "}
        <span onClick={navigateToSignUp} className="underline text-black">
          Sign Up
        </span>
      </p>
    </main>
  );
};

export default SignInWithEmailPage;
