import { travelPlacesClient } from "../../configs/axios.config";
import { Destination } from "../../models/destination";

export async function getDestination(
  city: string,
  destinationId: string
): Promise<Destination | null> {
  if (!destinationId) throw new Error("Destination ID is required");

  if (!city) throw new Error("City is required");

  const response = await travelPlacesClient.get(
    `/cities/${city}/${destinationId}`
  );

  return response.data;
}