import React from "react";

const TermsOfServicePage = () => {
  return (
    <div className="w-full h-full overflow-y-scroll p-2">
      <h1 className="text-xl font-semibold underline">Cookie Policy</h1>
      <p>
        This Cookie Policy explains how our web application uses cookies and
        similar technologies to collect and store information when you visit our
        website. By using this web application, you consent to the use of
        cookies as described in this policy.
      </p>
      <p>
        {" "}
        1. What Are Cookies? Cookies are small text files that are placed on
        your device when you visit a website. They help websites remember your
        actions and preferences over time, making your experience smoother and
        more personalized.
      </p>
      <p>
        {" "}
        2. Types of Cookies We Use * Essential Cookies: These cookies are
        necessary for the basic functionality of our website. They allow you to
        navigate through the site and use features like secure login areas. *
        Analytical/Performance Cookies: These cookies allow us to analyze how
        visitors use our site. We use this information to improve the
        functionality and content of our website. For example, we use Google
        Analytics to collect data on visitor behavior. * Functional Cookies:
        These cookies allow our website to remember choices you make (such as
        your preferred language or region) to provide enhanced and more
        personalized features. * Marketing Cookies: These cookies track your
        browsing habits to deliver relevant advertisements. They may be used by
        third-party advertising networks to show you ads on other websites.
      </p>{" "}
      <p>
        3. How Long Do Cookies Last? * Session Cookies: These cookies are
        temporary and are deleted once you close your browser. * Persistent
        Cookies: These cookies remain on your device for a specified period or
        until you delete them. They are used to remember your preferences for
        future visits.
      </p>{" "}
      <p>
        4. Third-Party Cookies We may also use third-party cookies for
        analytical and marketing purposes. These third-party services help us
        understand how users interact with our website and deliver personalized
        advertisements. Examples include: * Google Analytics: We use this
        service to track and report on website traffic and user behavior. *
        Advertising Networks: We may allow advertising networks to collect
        information through cookies for ad targeting and optimization.{" "}
      </p>
      <p>
        {" "}
        5. How to Manage Cookies You have the right to control the use of
        cookies. You can do this by adjusting your browser settings. Most
        browsers allow you to: * Block or delete cookies: You can remove
        existing cookies and prevent the installation of new cookies in your
        browser settings. * Control third-party cookies: Some browsers allow you
        to block third-party cookies separately. Please note that disabling
        certain cookies may affect the functionality of our website and your
        ability to access certain features.
      </p>{" "}
      <p>
        {" "}
        6. Cookie Consent Upon your first visit to our website, you will be
        prompted with a cookie consent banner. You can choose to accept all
        cookies or manage your preferences by selecting which types of cookies
        to enable or disable.
      </p>{" "}
      <p>
        {" "}
        7. Changes to Our Cookie Policy We may update our Cookie Policy from
        time to time to reflect changes in our practices or legal requirements.
        We will notify you of any significant changes by posting the updated
        policy on our website or through other communication methods.{" "}
      </p>
      8. Contact Us If you have any questions about our Cookie Policy or how we
      use cookies, please contact us at [eleplancontact@gmail.com].
      <h1 className="text-xl font-semibold underline">
        Terms and Conditions of Service
      </h1>
      <p>
        1. Acceptance of Terms: By using this web application, the user agrees
        to all terms and conditions set forth in this document.
      </p>{" "}
      <p>
        2. Personal Information: The web application will collect personal
        information solely for the purpose of providing automated trip planning
        services. The collected information may include contact details, travel
        data, and itinerary information authorized by the user.
      </p>{" "}
      <p>
        3. Use of Information: The personal information collected by the web
        application will be used to improve services and enhance the user
        experience. It will not be used for purposes unrelated to automated trip
        planning.
      </p>{" "}
      <p>
        4. Security Measures: We implement security measures to protect personal
        information and ensure it will not be disclosed or used without
        authorization.{" "}
      </p>
      <p>
        5. Changes and Notifications: We may update the terms and conditions of
        service as necessary and will inform users through appropriate channels.
      </p>
      6. User Agreement: By using this web application, the user agrees to all
      terms and conditions of service.{" "}
      <ul className="list-disc ml-5">
        <li>
          1) Rights and Responsibilities of Users * Proper Use: Users must not
          use the service for illegal activities, actions that infringe on the
          rights of others, or actions that disrupt the service usage of other
          users. * Responsibility for Information Provided: Users are
          responsible for providing accurate information and updating it when
          changes occur.
        </li>
        <li>
          {" "}
          2) Scope of Responsibility of Service Providers * Service Provision:
          The service provider does not guarantee that the service will function
          continuously or without errors. The service provider reserves the
          right to halt or modify the service as necessary. * Disclaimer: The
          service provider is not liable for damages arising from the use of the
          web application, such as lost data or technical issues.
        </li>
        <li>
          3) Data Retention * Retention Period: Personal data will be retained
          for as long as necessary to provide the service or until the user
          requests the deletion of the data.{" "}
        </li>
        <li>
          4) Specific Requirements for Devices and Browsers * Supported Browsers
          and Devices: Users must ensure that their device and browser are
          compatible with the service to ensure optimal functionality.
        </li>
      </ul>{" "}
    </div>
  );
};

export default TermsOfServicePage;
