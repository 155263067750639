import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Stack } from "@mui/material";
import React, { FC } from "react";

type Props = {
  generateItinerary: () => void;
};

const Header: FC<Props> = ({ generateItinerary }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      className="w-full"
    >
      <Stack gap={0.5}>
        <h1 className="text-title font-semibold">My Destinations</h1>
        <p className="text-gray-400 text-sm">(You can edit in next page)</p>
      </Stack>
      <ArrowPathIcon onClick={() => generateItinerary()} className="size-6" />
    </Stack>
  );
};

export default Header;
