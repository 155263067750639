import { useMutation } from "@tanstack/react-query";
import { itineraryGeneratorClient } from "../../configs/axios.config";
import { isAxiosError } from "axios";

const MAXIMUM_TRIP_DAYS = 7;
const MINIMUM_CATEGORIES = 3;

type Params = {
  currentCountry: string | null;
  currentDate: string | null;
  numOfDays: string | null;
  selectedTypes: string[] | undefined;
};

export function useGenerateItinerary(params: Params) {
  const { currentCountry, currentDate, numOfDays, selectedTypes } = params;
  const mutation = useMutation({
    mutationKey: [
      "fetch itinerary detail",
      currentCountry,
      currentDate,
      numOfDays,
      selectedTypes,
    ],
    mutationFn: async () => {
      try {
        if (!currentCountry || !currentDate || !numOfDays || !selectedTypes)
          return null;

        const selectedCountry = currentCountry.replace(/, /g, "_");

        if (parseInt(numOfDays) > MAXIMUM_TRIP_DAYS)
          throw new Error(
            `The trip could not be more than ${MAXIMUM_TRIP_DAYS} days`
          );

        if (selectedTypes.length < MINIMUM_CATEGORIES)
          throw new Error(
            `Please select at least ${MINIMUM_CATEGORIES} categories`
          );

        const searchParams = new URLSearchParams({
          city: selectedCountry,
          days: numOfDays,
          categories: selectedTypes.join(","),
          is_kid_friendly: "true",
          is_old_friendly: "true",
          trip_date : currentDate,
        });

        const endpoint = `${process.env.URPLANTRIP_GENERATOR_ENDPOINT}/${process.env.API_VERSION}/itinerary`;

        const response = await itineraryGeneratorClient.get(
          new URL(`?${searchParams.toString()}`, endpoint).toString()
        );

        return response.data;
      } catch (error: any) {
        let errorMessage: string = error.message;

        if (isAxiosError(error)) {
          errorMessage =
            error?.response?.data?.detail || "Failed to get itinerary detail";
        }

        throw new Error(errorMessage);
      }
    },
  });

  return mutation;
}
