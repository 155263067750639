import React, { useContext, useEffect, useState } from "react";
import { User } from "@supabase/supabase-js";
import { createContext, FC, ReactNode } from "react";
import { StripeCustomer } from "../models/stripeCustomer.model";
import { useQueryParam } from "../hooks/useQueryParam";
import { useFetchTrip } from "../hooks/useFetchTrip";
import { Trip, TripDetail } from "../models/trip.model";
import { useStripeCustomerInfo } from "../hooks/useStripeCustomerInfo";
import { Empty } from "../models/misc.model";

type ItineraryPageContextType = {
  stripeCustomer: {
    stripeCustomerInfo: StripeCustomer | null | undefined;
    loadingStripeInfo: boolean;
    loadingUserInfo: boolean;
    user: User | null;
  };
  tripId: string | null;
  itinerary: TripDetail | null;
  trip: Trip | Empty;
  view: string | null;
  currentSelectedDay: any;
  setCurrentSelectedDay: any;
  handleUpdateItinerary: any;
  loadingTrip: boolean;
} | null;

type ProviderProps = {
  children: ReactNode;
};

export const ItineraryPageContext =
  createContext<ItineraryPageContextType>(null);

export const ItineraryPageProvider: FC<ProviderProps> = ({ children }) => {
  const stripeCustomer = useStripeCustomerInfo();
  const [tripId, view] = [useQueryParam("tripId"), useQueryParam("view")];
  const { data: trip, isFetching: loadingTrip } = useFetchTrip(
    tripId as string
  );
  const [itinerary, setItinerary] = useState<TripDetail | null>(null);
  const [currentSelectedDay, setCurrentSelectedDay] = useState(null);

  useEffect(() => {
    if (!trip) return;
    setItinerary(trip.itinerary);
  }, [trip]);

  function handleUpdateItinerary(newItinerary) {
    setItinerary(
      (prev) =>
        ({
          ...prev,
          itinerary: newItinerary,
        }) as TripDetail
    );
  }

  return (
    <ItineraryPageContext.Provider
      value={{
        stripeCustomer,
        tripId,
        itinerary,
        trip,
        view,
        currentSelectedDay,
        setCurrentSelectedDay,
        handleUpdateItinerary,
        loadingTrip,
      }}
    >
      {children}
    </ItineraryPageContext.Provider>
  );
};

export const useItineraryPageContext = () => {
  const context = useContext(ItineraryPageContext);
  if (!context) {
    throw new Error(
      "useItineraryPageContext must be used within a ItineraryPageProvider"
    );
  }
  return context;
};
