import axios from "axios";

function isResponseError(status: number) {
  return status < 200 || status >= 300;
}

function getAuthToken(): string | null {
  const authToken = localStorage.getItem(
    `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token`
  );

  return authToken ? JSON.parse(authToken).access_token : null;
}

export const itineraryManagementClient = axios.create({
  baseURL: `${process.env.ITINERARY_MANAGEMENT_ENDPOINT}/v1`,
  validateStatus: () => true, // by pass default axios error handling
});

export const itineraryGeneratorClient = axios.create({});

export const paymentClient = axios.create({
  baseURL: import.meta.env.VITE_PAYMENT_API_ENDPOINT,
});

export const travelPlacesClient = axios.create({
  baseURL: import.meta.env.VITE_TRAVEL_PLACES_API_ENDPOINT,
  validateStatus: () => true, // by pass default axios error handling
});

itineraryManagementClient.interceptors.response.use((response) => {
  if (isResponseError(response.status)) {
    throw new Error(response.data.message);
  }
  return response;
});

itineraryManagementClient.interceptors.request.use((config) => {
  const accessToken = getAuthToken();

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

itineraryGeneratorClient.interceptors.response.use((response) => {
  if (isResponseError(response.status)) {
    throw new Error(response.data.message);
  }
  return response;
});

itineraryGeneratorClient.interceptors.request.use((config) => {
  const accessToken = getAuthToken();

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

paymentClient.interceptors.response.use((response) => {
  if (isResponseError(response.status)) {
    throw new Error(response.data.message);
  }
  return response;
});

travelPlacesClient.interceptors.response.use((response) => {
  if (isResponseError(response.status)) {
    throw new Error(response.data.message);
}
  return response;
});

export const featureFlagClient = axios.create({
  baseURL: import.meta.env.VITE_FEATURE_FLAG_ENDPOIN,
  validateStatus: () => true,
});

featureFlagClient.interceptors.request.use((config) => {
  const accessToken = getAuthToken();

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

featureFlagClient.interceptors.response.use((response) => {
  if (isResponseError(response.status)) {
    throw new Error(response.data.message);
  }
  return response;
});
