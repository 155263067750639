import { Stack } from "@mui/material";
import { Button } from "@nextui-org/react";
import React, { FC } from "react";
import { useNavigate } from "react-router";

type Props = {
  message: string;
  redirectLink: string;
  buttonLabel: string;
};

const ErrorPageWithRedirect: FC<Props> = ({
  message,
  buttonLabel,
  redirectLink,
}) => {
  const navigate = useNavigate();

  function handleNavigate() {
    navigate(redirectLink);
  }
  return (
    <Stack
      className="h-full w-full"
      alignItems={"center"}
      justifyContent={"center"}
      spacing={2}
    >
      <h2 className="text-red-500 font-semibold text-lg">{message}</h2>
      <Button
        className="bg-primary h-[48px] w-[150px]"
        onClick={handleNavigate}
      >
        {buttonLabel}
      </Button>
    </Stack>
  );
};

export default ErrorPageWithRedirect;
