import { css } from "@emotion/css";
import React, { FC, ReactNode } from "react";

const Menu: FC<{
  content: string;
  onClick?: any;
  icon?: ReactNode;
}> = ({ content, onClick, icon }) => {
  return (
    <div
      onClick={onClick}
      className={css`
        padding: 1rem 0;
        display: flex;
        column-gap: 10px;
        align-items: center;
        font-weight: 500;
        color: black;
      `}
    >
      {icon}
      {content}
    </div>
  );
};

export default Menu;
