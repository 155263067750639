import { featureFlagClient } from "../../configs/axios.config";
import { FeatureFlagResponse } from "../../models/api.model";
import { FeatureFlag } from "../../models/featureFlag.model";

export async function fetchAllFeatureFlags(): Promise<FeatureFlag[]> {
  const { data } =
    await featureFlagClient.get<FeatureFlagResponse<FeatureFlag[]>>(
      "/feature-flag"
    );
  return data.data;
}
