import React, { FC } from "react";
import { toast } from "react-hot-toast";
import { signOut } from "../../../libs/services/auth.service";
import DesktopDropDown from "./_components/DesktopDropDown";
import MobileBottomSheet from "./_components/MobileBottomSheet";

export const NavBottomSheet: FC<{
  showSheet: boolean;
  setShowSheet: (prev: boolean) => void;
}> = ({ showSheet, setShowSheet }) => {
  const isDesktopUser = window.innerWidth >= 1024;

  async function handleSignOut() {
    try {
      await signOut();

      window.location.reload();
    } catch (error) {
      toast.error(error.message);
    }
  }

  if (isDesktopUser) {
    return showSheet && <DesktopDropDown setShowSheet={setShowSheet} />;
  }

  return (
    <MobileBottomSheet
      showSheet={showSheet}
      setShowSheet={setShowSheet}
      handleSignOut={handleSignOut}
    />
  );
};
