import { Stack } from "@mui/material";
import { Button, CircularProgress } from "@nextui-org/react";
import React, { lazy, MouseEvent } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router";
import ReachMaximumModal from "../../../components/common/ReachMaximumModal";
import Title from "../../../components/common/Title";
import { useFetchTrips } from "../../../hooks/useFetchTrips";
import { useManageTrip } from "../../../hooks/useManageTrip";
import { useModal } from "../../../hooks/useModal";
import { useStripeCustomerInfo } from "../../../hooks/useStripeCustomerInfo";
import MyTrips from "./MyTrips";

// const MAXIMUM_FREE_ITINERARY = 1;

const LoadingDestinations = lazy(
  () => import("../../../components/pages/home/LoadingDestinations")
);

const ItineraryList = () => {
  const {
    user,
    loadingUserInfo: loadingUser,
    stripeCustomerInfo,
  } = useStripeCustomerInfo();

  const navigate = useNavigate();

  // const { data: featureFlags } = useFetchFeatureFlags();

  // const isPaymentEnabled = featureFlags?.payment ?? false;

  const {
    data: itineraries,
    isPending: isLoadingItineraries,
    refetch: refreshTrips,
  } = useFetchTrips({
    userId: user?.id ?? null,
  });

  const { isOpen: isShowReachLimitModal, handleClose: closeReachLimitModal } =
    useModal();

  // const { data: createdItineraryNum, isFetching: isFetchingUserLog } =
  //   useFetchUserItineraryLog(user?.id ?? "");

  const { handleNavigateCreateTrip, handleDeleteTrip } = useManageTrip({
    stripeCustomerInfo,
  });

  if (loadingUser)
    return (
      <Stack className="h-[300px] items-center justify-center">
        <CircularProgress color="primary" label="Loading user info..." />
      </Stack>
    );

  // const hasReachedLimit =
  //   (createdItineraryNum ?? MAXIMUM_FREE_ITINERARY) >= MAXIMUM_FREE_ITINERARY;

  return (
    <div className="flex flex-col w-full items-center gap-y-3 text-black">
      <Title text="Upcoming Plans" position="left" />
      <ReachMaximumModal
        isOpen={isShowReachLimitModal}
        onClose={closeReachLimitModal}
        onConfirm={() => navigate("/pricing")}
      />
      {user ? (
        isLoadingItineraries ? (
          <LoadingDestinations />
        ) : itineraries.length > 0 ? (
          <MyTrips
            itineraries={itineraries}
            onDeleteItinerary={async (tripId) => {
              await handleDeleteTrip(tripId);
              refreshTrips();
            }}
          />
        ) : (
          <div className="h-[200px] w-full flex items-center justify-center">
            <h3 className="text-center text-black">You have no itineraries</h3>
          </div>
        )
      ) : (
        <h3 className="h-[200px] text-center flex items-center justify-center">
          You have to sign in to see your created itineraries
        </h3>
      )}
      <Button
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          // if (isFetchingUserLog || loadingUser) return;

          // if (!user) {
          //   navigate("/sign-in");
          //   return;
          // }

          // if (
          //   hasReachedLimit &&
          //   !Membership.isValid(stripeCustomerInfo) &&
          //   isPaymentEnabled
          // )
          //   return showReachLimitModal();

          handleNavigateCreateTrip(e);
        }}
        startContent={<FaPlus size={13} />}
        id="cy-create-trip-button"
        className="w-[148px] bg-primary text-[17px] min-h-[41px] mt-5 rounded-full hover:outline-none hover:border-none"
      >
        <p className="text-buttonTextColor font-semibold">New Plan</p>
      </Button>
    </div>
  );
};

export default ItineraryList;
