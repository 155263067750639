import { itineraryManagementClient } from "../../configs/axios.config";
import { UserItineraryLog } from "../../models/userItineraryLog.model";

export async function getUserItineraryLog(
  userId: string
): Promise<UserItineraryLog> {
  const response = await itineraryManagementClient.get(
    `/user-itinerary-log/${userId}`
  );
  if (!response.data) throw new Error("Failed to get user itinerary log");
  return response.data;
}
