import React from "react";

const PrivacyPolicyPage = () => {
  return (
    <div className="w-full h-full overflow-y-scroll p-2">
      <h1 className="font-semibold text-xl underline">Privacy Policy</h1>
      <ul>
        <li>
          {" "}
          1. Information We Collect: We collect personal information that users
          provide, such as names, addresses, emails, payment details, and
          related travel information.
        </li>
        <li>
          {" "}
          2. Use of Information: We use this information to improve and develop
          our services, communicate with users about services, and provide
          customer support.
        </li>
        <li>
          3. Disclosure of Information: We will not disclose users' personal
          information to third parties without permission, except as required by
          law or for the benefit of the user.
        </li>
        <li>
          4. Security of Information: We implement security measures to protect
          the personal information we collect.
        </li>
        <li>
          5. Contact Us: If you have any questions about our privacy policy or
          these terms and conditions, please contact us at [
          eleplancontact@gmail.com ].
        </li>
      </ul>
      <h1 className="text-xl font-semibold underline">Data Security</h1>
      We place the utmost importance on protecting your data. We implement
      strict technical and administrative measures to safeguard your personal
      information from unauthorized access, use, or disclosure. Our security
      measures include:
      <ul>
        <li>
          1. Data Encryption: * We use SSL/TLS encryption technology to protect
          the transmission of information between your browser and our servers.
          * Sensitive information, such as passwords and payment details, is
          encrypted when stored.
        </li>
        <li>
          {" "}
          2. Access Control: * We limit access to personal data to employees who
          need it for their work. * All employees are required to sign
          confidentiality agreements. * We utilize multi-factor authentication
          systems for accessing internal systems.{" "}
        </li>
        <li>
          {" "}
          3. Monitoring and Testing: * We conduct regular security audits. * We
          hire external security experts to perform penetration tests.{" "}
        </li>
        <li>
          {" "}
          4. Data Backup and Recovery: * We regularly back up data and store it
          in a secure location. * We have an emergency data recovery plan in
          place.
        </li>
        <li>
          {" "}
          5. Malware Protection: * We use advanced antivirus and malware
          protection software. * Our systems and software are regularly updated
          to protect against security vulnerabilities.
        </li>
        <li>
          {" "}
          6. Data Breach Management: * We have procedures in place for
          responding to data breaches. * In the event of a breach, we will
          notify affected individuals as required by law as soon as possible.
        </li>
        <li>
          {" "}
          7. Employee Training: * All employees receive regular data security
          training. * We have clear policies and procedures regarding the
          handling of personal information.
        </li>
        <li>
          {" "}
          8. Data Disposal:
          <ul>
            <li> * We have clear data retention policies.</li>
            <li>
              {" "}
              * When data is no longer needed, we securely destroy it in
              accordance with industry standards.
            </li>
          </ul>{" "}
          Your Responsibilities:
          <ul>
            <li>
              * We recommend that you keep your login information confidential.
            </li>
            <li>
              * If you suspect unauthorized use of your account, please contact
              us immediately.
            </li>
          </ul>
        </li>
      </ul>{" "}
      <span className="font-semibold">Reporting Security Issues: </span>If you
      encounter any security issues, please report them to us immediately at: [
      eleplancontact@gmail.com ]. Please note that while we use rigorous
      security measures, no system is 100% secure. We will continue to work
      diligently to enhance our security practices.
    </div>
  );
};

export default PrivacyPolicyPage;
