import { useQuery } from "@tanstack/react-query";
import { fetchPresetTrip } from "../../libs/services/itinerary-preset.service";
import { Empty } from "../../models/misc.model";
import toast from "react-hot-toast";

export function useFetchPresetTrip(presetTripId: string | Empty) {
  const query = useQuery({
    queryKey: ["fetch preset trip", presetTripId],
    queryFn: async () => {
      try {
        if (!presetTripId) return null;

        return await fetchPresetTrip(presetTripId);
      } catch (error: any) {
        toast.error(error.message);
      }
    },
  });

  return query;
}
