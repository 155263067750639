import { Stack } from "@mui/material";
import { Button } from "@nextui-org/react";
import classNames from "classnames";
import React, { FC } from "react";
import { Empty } from "../../models/misc.model";

type Props = {
  currentValue: string[];
  options: string[];
  setter: (newValue: string[]) => void;
  label?: string | Empty;
};

const RoundedMultipleChoices: FC<Props> = ({
  currentValue,
  options,
  setter,
  label = "Choose one",
}) => {
  return (
    <Stack className="w-full" gap={1}>
      <h1 className="text-sm">{label}</h1>
      <Stack className="w-full overflow-x-scroll" direction={"row"} gap={1}>
        {options.map((option) => {
          const isSelected = currentValue.includes(option);
          const buttonClassName = classNames(
            "focus:outline-none focus:border-gray-300 rounded-full border-1",
            {
              "bg-primary border-none": isSelected,
            }
          );
          return (
            <Button
              key={option}
              variant={isSelected ? "solid" : "bordered"}
              className={buttonClassName}
              disableRipple
              onClick={() => {
                setter(
                  isSelected
                    ? currentValue.filter((value) => value !== option)
                    : [...currentValue, option]
                );
              }}
            >
              {option}
            </Button>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default RoundedMultipleChoices;
