import { css } from "@emotion/css";
import { Stack } from "@mui/material";
import { Button } from "@nextui-org/react";
import React, { useMemo } from "react";
import {
  FaBloggerB,
  FaHistory,
  FaSignInAlt,
  FaSignOutAlt,
} from "react-icons/fa";
import { RxGear } from "react-icons/rx";
import { useNavigate } from "react-router";
import { BottomSheet } from "react-spring-bottom-sheet";
import CONSTANTS from "../../../../constants";
import { useStripe } from "../../../../hooks/useStripe";
import { useStripeCustomerInfo } from "../../../../hooks/useStripeCustomerInfo";
import { Membership } from "../../../../models/classes/Membership.class";
import UpgradeToProButton from "../../UpgradeToProButton";
import Menu from "./Menu";
import { useFetchFeatureFlags } from "../../../../hooks/fetch-data/useFetchFeatureFlags";

function MobileBottomSheet({ showSheet, setShowSheet, handleSignOut }) {
  const navigate = useNavigate();

  const { stripeCustomerInfo, user, loadingUserInfo } = useStripeCustomerInfo();

  const { data: featureFlags } = useFetchFeatureFlags();

  const { handleNavigateCustomerPortal } = useStripe(
    user,
    Membership.isValid(stripeCustomerInfo)
  );

  const isPaymentEnabled = featureFlags?.payment ?? false;

  const menus = useMemo(
    () => [
      {
        title: "Blogs",
        icon: <FaBloggerB />,
        onClick: () => {
          window.location.href = CONSTANTS.ELEPLAN_BLOG_URL;
        },
      },
      {
        title: "Your past trips",
        icon: <FaHistory />,
        onClick: () => {
          navigate("/past-trips");
        },
      },
      {
        title: user ? "Sign Out" : "Sign In",
        icon: user ? <FaSignOutAlt /> : <FaSignInAlt />,
        onClick: async () => {
          if (!user) return navigate("/sign-in");
          await handleSignOut();
        },
      },
    ],
    [user]
  );

  return (
    <BottomSheet
      id="cy-bottom-sheet"
      open={showSheet}
      onDismiss={() => {
        setShowSheet((prev: boolean) => !prev);
      }}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          padding: 2rem 1.5rem;
        `}
      >
        {loadingUserInfo ? null : user ? <Menu content={user.email!} /> : null}
        {isPaymentEnabled &&
          (stripeCustomerInfo?.plan_active ? (
            <Button
              onClick={handleNavigateCustomerPortal}
              startContent={<RxGear />}
              className="text-black py-6"
              variant="bordered"
              color="primary"
              fullWidth
            >
              Manage your subscription
            </Button>
          ) : (
            stripeCustomerInfo && <UpgradeToProButton />
          ))}
        <Stack>
          {menus.map((menu, _) => {
            return (
              <Menu
                onClick={menu.onClick}
                key={menu.title}
                content={menu.title}
                icon={menu.icon}
              />
            );
          })}
        </Stack>
      </div>
    </BottomSheet>
  );
}

export default MobileBottomSheet;
