import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { ItineraryPageProvider } from "../contexts/ItineraryPageContext";
import SignInWithEmailPage from "../routes/(auth)/sign-in/email/SignInWithEmailPage";
import SignInPage from "../routes/(auth)/sign-in/SignInPage";
import SignUp from "../routes/(auth)/sign-up/SignUp";
import ConfirmDestinationsPage from "../routes/confirm-destinations/ConfirmDestinationsPage";
import CreateItineraryPage from "../routes/create-itinerary/CreateItineraryPage";
import HomePage from "../routes/home/HomePage";
import ItineraryInformationPage from "../routes/itinerary-information/ItineraryInformationPage";
import ItineraryPage from "../routes/itinerary/ItineraryPage";
import PastTripsPage from "../routes/past-trips/PastTripsPage";
import PricingPage from "../routes/pricing/PricingPage";
import GoogleSearch from "../routes/test/GoogleSearch";
import TermsOfServicePage from "../routes/(policies)/terms-of-service/TermsOfServicePage";
import PrivacyPolicyPage from "../routes/(policies)/privacy/PrivacyPolicyPage";
import PresetItineraryPage from "../routes/preset-itinerary/PresetItineraryPage";
import SaveItineraryPage from "../routes/save-itinerary/SaveItineraryPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ErrorBoundary>
        <HomePage />
      </ErrorBoundary>
    ),
  },
  {
    path: "/sign-in",
    element: (
      <ErrorBoundary>
        <SignInPage />
      </ErrorBoundary>
    ),
  },
  {
    path: "/sign-in/email",
    element: (
      <ErrorBoundary>
        <SignInWithEmailPage />
      </ErrorBoundary>
    ),
  },
  {
    path: "/sign-up",
    element: (
      <ErrorBoundary>
        <SignUp />
      </ErrorBoundary>
    ),
  },
  {
    path: "/test/google-search",
    element: (
      <ErrorBoundary>
        <GoogleSearch />
      </ErrorBoundary>
    ),
  },
  {
    path: "/itinerary-information",
    element: (
      <ErrorBoundary>
        <ItineraryInformationPage />
      </ErrorBoundary>
    ),
  },
  {
    path: "/create-itinerary",
    element: (
      <ErrorBoundary>
        <CreateItineraryPage />
      </ErrorBoundary>
    ),
  },
  {
    path: "/confirm-destinations",
    element: (
      <ErrorBoundary>
        <ConfirmDestinationsPage />
      </ErrorBoundary>
    ),
  },
  {
    path: "/itinerary",
    element: (
      <ErrorBoundary>
        <ItineraryPageProvider>
          <ItineraryPage />
        </ItineraryPageProvider>
      </ErrorBoundary>
    ),
  },
  {
    path: "/pricing",
    element: (
      <ErrorBoundary>
        <PricingPage />
      </ErrorBoundary>
    ),
  },
  {
    path: "/past-trips",
    element: (
      <ErrorBoundary>
        <PastTripsPage />
      </ErrorBoundary>
    ),
  },
  {
    path: "/policies/terms-of-service",
    element: (
      <ErrorBoundary>
        <TermsOfServicePage />
      </ErrorBoundary>
    ),
  },
  {
    path: "/policies/privacy",
    element: (
      <ErrorBoundary>
        <PrivacyPolicyPage />
      </ErrorBoundary>
    ),
  },
  {
    path: "/preset-itinerary/:presetId",
    element: (
      <ErrorBoundary>
        <PresetItineraryPage />
      </ErrorBoundary>
    ),
  },
  {
    path: "/save-itinerary",
    element: (
      <ErrorBoundary>
        <SaveItineraryPage />
      </ErrorBoundary>
    ),
  },
]);
