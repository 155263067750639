import { XMarkIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useNavigate } from "react-router";

const CancelButton = () => {
  const navigate = useNavigate();

  function handleNavigateBack() {
    navigate("/");
  }
  return (
    <div
      className="w-[45px] min-h-[45px] border border-solid rounded-full flex items-center justify-center"
      onClick={handleNavigateBack}
    >
      <XMarkIcon className="size-6" />
    </div>
  );
};

export default CancelButton;
