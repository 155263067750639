import { MapIcon } from "@heroicons/react/24/outline";
import { Stack } from "@mui/material";
import React, { FC } from "react";
import {
  formatDestinationDescription,
  getDestinationMapUrl,
} from "../../../helper/destination.helper";
import { formatDestinationWeekText } from "../../../helper/util";
import { useFetchDestination } from "../../../hooks/fetch-data/useFetchDestination";
import DestinationCategories from "../../common/DestinationCategories";
import LoadingPage from "../../common/LoadingPage";
import Rating from "../../common/Rating";

type Props = {
  city: string;
  destinationId: string;
};

const BottomSheetDestination: FC<Props> = ({ city, destinationId }) => {
  const {
    data: destination,
    isFetching: isFetchingDestinationDetail,
    error: destinationFetchingError,
  } = useFetchDestination(city, destinationId);

  if (destinationFetchingError?.message) {
    return (
      <Stack
        className="text-black h-[275px] px-10"
        alignItems={"center"}
        justifyContent={"center"}
        spacing={2}
      >
        <h1>🥲</h1>
        <h2 className="text-gray-400 text-center">
          {destinationFetchingError?.message}
        </h2>
      </Stack>
    );
  }

  if (!destination)
    return (
      <Stack className="text-black h-[350px]">
        <LoadingPage message="Loading destination" />
      </Stack>
    );

  const {
    name_place,
    DCT: description,
    category,
    rating,
    opening_hours,
  } = destination;

  const googleMapUrl = name_place ? getDestinationMapUrl(name_place) : null;

  const categories = category?.split(",");

  function handleNavigateGoogleMapUrl() {
    if (!googleMapUrl) return;
    window.open(googleMapUrl, "_blank");
  }

  return (
    <Stack gap={2} padding={3} className="text-black">
      {isFetchingDestinationDetail ? (
        <section className="h-[300px]">
          <LoadingPage message="Loading destination" />
        </section>
      ) : (
        <>
          <nav className="flex justify-between">
            <Stack direction={"row"} className="w-[85%]">
              <h1 className="text-xl font-semibold line-clamp-1">
                {name_place}
              </h1>
              <Rating rating={rating ?? 0} />
            </Stack>
            <MapIcon
              onClick={() => {
                handleNavigateGoogleMapUrl();
              }}
              className="size-6"
            />
          </nav>
          <DestinationCategories categories={categories ?? []} />
          <h2 className="font-semibold">Description</h2>
          {formatDestinationDescription(description ?? "").map(
            (desc, index) => (
              <p key={index} className="text-gray-400 text-sm h-full">
                {desc}
              </p>
            )
          )}
          <h2 className="font-semibold">
            Time Available{" "}
            <span className="text-gray-400 font-bold">(by Google Maps)</span>
          </h2>
          <Stack>
            {opening_hours ? (
              opening_hours.weekday_text.map((dayWithTime, index) => {
                const [day, time] = formatDestinationWeekText(dayWithTime);
                return (
                  <p key={index} className="text-black text-sm h-full">
                    <span className="font-semibold">{day}</span> : {time}
                  </p>
                );
              })
            ) : (
              <p className="text-center text-gray-400">No information</p>
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default BottomSheetDestination;
