import { Stack } from "@mui/material";
import { Button, Card, CardBody } from "@nextui-org/react";
import React, { FC } from "react";
import { Trip } from "../../../models/trip.model";
import { useNavigate } from "react-router";

type Props = {
  trip: Trip;
};

function formatDate(date: string) {
  const [year, month, extractedDate] = date.split("-");
  return `${extractedDate}/${month}/${year}`;
}

const PastTripCard: FC<Props> = ({ trip }) => {
  const navigate = useNavigate();
  const {
    itinerary: { imgCover, name, date },
    _id,
  } = trip;

  function handleNavigateToTrip() {
    console.log("somethign");
    navigate(`/itinerary?tripId=${_id}`);
  }

  return (
    <div onClick={handleNavigateToTrip}>
      <Card>
        <CardBody className="p-2">
          <Stack className="w-full" gap={1}>
            <img
              className="rounded-xl h-[100px] object-cover w-full"
              src={imgCover}
              alt={`${name} image cover`}
            />
            <p className="font-semibold text-base">{name}</p>
            <p className="text-sm text-gray-500">
              {date ? formatDate(date) : "-"}
            </p>
          </Stack>
        </CardBody>
      </Card>
    </div>
  );
};

export default PastTripCard;
