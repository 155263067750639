import { useQuery } from "@tanstack/react-query";
import { useUser } from "./useUser";
import {
  createStripeCustomerInfo,
  doesStripeCustomerExist,
  getStripeCustomerInfo,
} from "../libs/services/stripeCustomers.service";
import { StripeCustomer } from "../models/stripeCustomer.model";

export function useStripeCustomerInfo() {
  const { user, loading: loadingUserInfo } = useUser();
  const { data: stripeCustomerInfo, isFetching: loadingStripeInfo } = useQuery({
    queryKey: ["user", user?.id, loadingUserInfo],
    queryFn: async () => {
      if (!user) return null;

      const doesUserExist = await doesStripeCustomerExist(user.id);

      let userInfo: StripeCustomer;

      if (doesUserExist) {
        userInfo = await getStripeCustomerInfo(user.id);
      } else {
        userInfo = await createStripeCustomerInfo(user.id);
      }


      return userInfo;
    },
    enabled: Boolean(user?.id),
  });

  return { stripeCustomerInfo, loadingStripeInfo, user, loadingUserInfo };
}
