import React, { FC } from "react";
import { TModal } from "../../../models/component.model";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
} from "@nextui-org/react";
import { Stack } from "@mui/material";
import { getCenterModalProps } from "../../../libs/utils/component.util";

const PromptSignInModal: FC<TModal> = ({ onConfirm, ...modalProps }) => {
  return (
    <Modal {...getCenterModalProps()} {...modalProps}>
      <ModalContent>
        <ModalBody>
          <Stack
            className="text-black"
            alignItems={"center"}
            justifyContent={"center"}
            spacing={1}
          >
            <h1 className="text-xl font-semibold">Oops!</h1>
            <h2 className="text-lg font-medium">
              Sign in to save your itinerary
            </h2>
          </Stack>
        </ModalBody>
        <ModalFooter className="flex">
          <Button
            className="font-medium"
            fullWidth
            onClick={modalProps.onClose}
          >
            Cancel
          </Button>
          <Button
            className="text-black font-medium"
            fullWidth
            color="primary"
            onClick={onConfirm}
          >
            Sign In
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PromptSignInModal;
