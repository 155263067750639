import { getUserItineraryLog } from "../../libs/services/user-itinerary-log.service";
import { Empty } from "../misc.model";
import { StripeCustomer } from "../stripeCustomer.model";

const MAXIMUM_FREE_ITINERARY = 1;

export class Membership {
  private constructor() {}

  static isValid(
    stripeCustomerInfo: StripeCustomer | Empty,
    comparingDate: Date = new Date()
  ): boolean {
    if (!stripeCustomerInfo) return false;

    const { free_trial_expires, plan_expires, plan_active } =
      stripeCustomerInfo;

    if (!plan_active || !free_trial_expires) return false;

    if (comparingDate <= new Date(free_trial_expires)) return true;

    if (!plan_expires) return false;

    if (comparingDate <= new Date(plan_expires)) return true;

    return false;
  }

  static async hasReachedFreeItineraryLimit(userId: string) {
    const { createdItineraryNum } = await getUserItineraryLog(userId);

    return createdItineraryNum >= MAXIMUM_FREE_ITINERARY;
  }
}
