import React from "react";
import ReactDOM from "react-dom/client";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./index.css";
import "react-spring-bottom-sheet/dist/style.css";
import { RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import { NextUIProvider } from "@nextui-org/react";
import { router } from "./configs/routes";
import { NuqsAdapter } from "nuqs/adapters/react";

Sentry.init({
  dsn: "https://cf6dce81edcc4e1b71e4b0f7ce95eada@o4507025679712256.ingest.us.sentry.io/4507102541381632",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitorin
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")).render(
  <QueryClientProvider client={queryClient}>
    <Toaster position="top-right" />
    <NuqsAdapter>
      <NextUIProvider>
        <main className="w-full h-svh lg:hidden text-black">
          {/* <h1>The site is under maintenance...</h1> */}
          <RouterProvider router={router} />
        </main>
        <div className="hidden flex-col items-center justify-center w-full min-h-lvh lg:flex">
          <h1 className="text-lg">
            Sorry, but the site is available only on mobile devices.
          </h1>
        </div>
      </NextUIProvider>
    </NuqsAdapter>
  </QueryClientProvider>
);
