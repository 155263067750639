import { useQuery } from "@tanstack/react-query";
import {
  fetchPresetTrips,
  FetchPresetTripsParams,
} from "../../libs/services/itinerary-preset.service";

export function useFetchPresetTrips({
  isPublished = undefined,
}: FetchPresetTripsParams) {
  const query = useQuery({
    queryKey: ["fetch all preset trips"],
    queryFn: async () => {
      return await fetchPresetTrips({ isPublished });
    },
  });
  return query;
}
