import React, { FC } from "react";
import { RxHamburgerMenu } from "react-icons/rx";

type HamProps = {
  onClick: () => void;
};

const Hamburger: FC<HamProps> = ({ onClick }) => {
  return (
    <button
      className="flex flex-col items-center justify-center bg-transparent border-none outline-none accent-transparent rounded-xl focus:outline-none p-0 cy-hamburger"
      onClick={onClick}
    >
      <RxHamburgerMenu size={24} className="text-color cursor-pointer" />
    </button>
  );
};

export default Hamburger;
