export const coverImages = {
  "Thailand, Bangkok" : "https://a.cdn-hotels.com/gdcs/production172/d459/3af9262b-3d8b-40c6-b61d-e37ae1aa90aa.jpg?impolicy=fcrop&w=800&h=533&q=medium",
  "Thailand, Chiangmai" : "https://www.holidify.com/images/bgImages/CHIANG-MAI.jpg",
  "Korea, Seoul" : "https://media.cntraveller.com/photos/6401da3fc650bff59aa53ef8/16:9/w_2560%2Cc_limit/GettyImages-902452584%2520(1).jpg",
  "Korea, Busan" : "https://media.timeout.com/images/105996093/image.jpg",
  "Japan, Tokyo" : "https://www.swedishnomad.com/wp-content/images/2017/02/Places-to-visit-in-Japan.jpg",
  "Japan, Osaka" : "https://images.unsplash.com/photo-1586264474608-abf0979b51bf?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "Taiwan, Taipei" : "https://images.unsplash.com/photo-1519126400611-d57095205936?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "Malaysia, KualaLumpur" : "https://images.unsplash.com/photo-1602427384420-71c70e2b2a2f?q=80&w=2906&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "Singapore" : "https://images.unsplash.com/photo-1496939376851-89342e90adcd?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "Switzerland, Geneva" : "https://images.unsplash.com/photo-1530878902700-5ad4f9e4c318?q=80&w=1934&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "Vietnam, Danang" : "https://images.unsplash.com/photo-1571713343936-b27b7bedddab?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
}

