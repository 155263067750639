import { Stack } from "@mui/material";
import classNames from "classnames";
import React, { ReactNode } from "react";

type ScrollViewProps = {
  axis: "horizontal" | "vertical";
  children: ReactNode;
  paddingX?: "large" | "small" | "none";
  header?: ReactNode;
};

const ScrollView: React.FC<ScrollViewProps> = ({
  axis,
  children,
  paddingX = "large",
  header,
}) => {
  const scrollViewClass = classNames(
    "flex overflow-scroll w-full p-2 py-3 gap-x-3 no-scrollbar",
    { "flex-col": axis === "vertical" },
    { "flex-row": axis === "horizontal" }
  );

  const containerClass = classNames(
    "flex gap-x-3 w-full",
    { "lg:px-36": paddingX === "large" },
    { "px-2": paddingX === "small" },
    { "px-0": paddingX === "none" }
  );
  return (
    <Stack className={containerClass}>
      <div className="px-2">{header}</div>
      <div className={scrollViewClass}>{children}</div>
    </Stack>
  );
};

export default ScrollView;
