import React from "react";
import { motion } from "framer-motion";

function DropDownMenu({ onClick, menu, index }) {
  return (
    <motion.div
      initial={{
        translateY: 10,
      }}
      animate={{
        translateY: 0,
      }}
      transition={{
        type: "tween",
        delay: 0.2 * index,
      }}
    >
      <li
        onClick={onClick}
        className="flex gap-x-2 items-center p-5 m-0 font-medium h-8 hover:bg-primary hover:text-white cursor-pointer transition-all"
      >
        {menu.icon}
        <p>{menu.title}</p>
      </li>
    </motion.div>
  );
}

export default DropDownMenu;
