import { Stack } from "@mui/material";
import { Button } from "@nextui-org/react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import LoadingPage from "../../components/common/LoadingPage";
import ConfirmActionModal from "../../components/common/modal/ConfirmActionModal";
import Title from "../../components/common/Title";
import NavBar from "../../components/pages/home/Navbar";
import CategoryCard from "../../components/pages/itineraryForm/CategoryCard";
import { getErrorMessage, removeEmptyItemsFromArray } from "../../helper/util";
import { useGetSearchParams } from "../../hooks/useGetSearchParams";
import { useModal } from "../../hooks/useModal";
import { useStripeCustomerInfo } from "../../hooks/useStripeCustomerInfo";
import FeedbackSection from "../../components/common/FeedbackSection";

const MINIMUM_CATEGORY_SELECTION = 3;

const CreateItineraryPage = () => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const { user, loadingStripeInfo, stripeCustomerInfo } =
    useStripeCustomerInfo();

  const {
    isOpen: isShowSubscriptionModal,
    handleClose: closeSubscriptionModal,
  } = useModal();

  const [error, setError] = useState({
    message: "",
    isError: false,
  });

  const [currentCountry, date, days] = useGetSearchParams<string>([
    { key: "country" },
    { key: "date" },
    { key: "days" },
  ]);

  const navigate = useNavigate();

  const { data: categories, isPending: isLoading } = useQuery({
    queryKey: ["categories", currentCountry],
    queryFn: async () => {
      try {
        if (!currentCountry) return;

        const convertedCountryInput = currentCountry.replace(/, /g, "_");

        const { data } = await axios.get(
          `${process.env.URPLANTRIP_GENERATOR_ENDPOINT}/${process.env.API_VERSION}/categories/${convertedCountryInput}`
        );

        if (data.status_code === 400)
          setError((_) => {
            return { message: data.detail, isError: true };
          });

        const categories = Object.keys(data);

        setSelectedCategories((_) => removeEmptyItemsFromArray(categories));

        return removeEmptyItemsFromArray(categories);
      } catch (error: any) {
        const errorMessage = getErrorMessage(error);

        toast.error(errorMessage);

        setError((prev) => ({
          message: errorMessage,
          isError: true,
        }));

        return null;
      }
    },
    enabled: Boolean(currentCountry),
  });

  function handleSelectCategory(category: string) {
    if (loadingStripeInfo) return;

    if (!stripeCustomerInfo) return;

    try {
      // if (!Membership.isValid(stripeCustomerInfo)) {
      //   toast.dismiss();
      //   showSubscriptionModal();
      //   return;
      // }

      setSelectedCategories((prev) => {
        const doesCategoryExist = prev.some((item) => item === category);

        if (doesCategoryExist) {
          const removedCategory = prev.filter((item) => item !== category);

          return removedCategory;
        }

        return [...prev, category];
      });
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  function handleSubmit() {
    try {
      if (selectedCategories.length < MINIMUM_CATEGORY_SELECTION) {
        toast.error(
          `Please select at least ${MINIMUM_CATEGORY_SELECTION} categories`
        );
        return;
      }

      const formattedCategories = selectedCategories.join(",");

      navigate(
        `/confirm-destinations?country=${currentCountry}&categories=${formattedCategories}&date=${date}&days=${days}`
      );
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  if (!currentCountry) {
    navigate("/");
    return null;
  }

  if (isLoading) return <LoadingPage message="Loading..." />;

  if (error.isError) throw new Error("Could not fetch categories");

  // if (!user) {
  //   toast.error("Please login to continue");
  //   setTimeout(() => {
  //     navigate("/sign-in");
  //   }, 1000);
  //   return null;
  // }

  return (
    <>
      <ConfirmActionModal
        title="Membership Required"
        description="Subscribe to a plan to adjust your travel style"
        isOpen={isShowSubscriptionModal}
        onClose={closeSubscriptionModal}
        confirmButtonLabel="Subscribe"
        confirmButtonColor="primary"
        confirmButtonTextColor="black"
        onConfirm={() => {
          navigate("/pricing");
        }}
      />
      <FeedbackSection user={user} />
      <div className="h-full w-[90%] lg:w-[40%] mx-auto pt-2 text-black flex flex-col justify-start pb-10">
        <NavBar user={user} />
        <Title text="My Travel Style" position="left" />
        <p className="text-gray-300 text-sm mt-1">
          {"(Choose at least 3 categories)"}
        </p>
        <div className="flex flex-col justify-start">
          {isLoading ? (
            <span className="border-solid border-4 border-r-gray-200 border-t-gray-200 border-purple-500 w-[40px] animate-spin h-[40px] rounded-full mx-auto my-20"></span>
          ) : categories ? (
            <Stack className="mt-6" gap={2}>
              {categories.map((type: string) => (
                <CategoryCard
                  title={type}
                  key={type}
                  onSelect={() => {
                    handleSelectCategory(type);
                  }}
                  isSelected={selectedCategories.includes(type)}
                />
              ))}
            </Stack>
          ) : (
            <h1 className="text-lg text-center h-[300px] flex items-center justify-center">
              Sorry, but we could not retrieve the categories for your
              destination 😢
            </h1>
          )}
        </div>
        <Button
          onClick={handleSubmit}
          className="mt-auto h-[56px] bg-primary font-semibold"
        >
          View my destinations
        </Button>
      </div>
    </>
  );
};

export default CreateItineraryPage;
